<template>
	<div v-if="isShow">
		<layout-top></layout-top>
		<div class="stepList">
			<el-steps :active="step" align-center>
				<el-step title="选择游戏分类">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=1 }">1</div>
					</div>
				</el-step>
				<el-step title="填写分类信息">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=2 }">2</div>
					</div>
				</el-step>
				<el-step title="填写商品信息">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=3 }">3</div>
					</div>
				</el-step>
				<el-step title="完成商品发布">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=4 }">
							✔
						</div>
					</div>
				</el-step>
			</el-steps>
		</div>

		<div class="lableTop">
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row class="content">
				<el-col :span="24">
					<div class="lableBg ">
						<img style="width: 80px;height: 80px;border-radius: 10px;margin-right: 15px;"
							:src="info.icon" />
						<div class=" gameClass">
							<div class="lableTitle">{{info.gameName}}</div>
							<div class="lableDetaile">{{info.districtName}}</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="hotMains" :style="{top: mainsTop+'px'}" v-if="receiveList.length != 0">
			<el-carousel :height=" receiveList.length == 2 ? '860px' : '430px'" :autoplay="false" @change="change">
				<el-carousel-item v-for="item in (Math.ceil(receiveTotal / 2) )">
					<div class="hotMain" v-for="(item,index) in receiveList">
						<div class="mainLeft fl">
							<div class="topLeft fl">
								<img :src="require('@/static/image/index/hotShop.png')"
									style="width: 32px;height: 32px;margin-right: 5px;" />
								<span>{{item.receiveGoodsType_dictText}}</span>
							</div>
							<div @click="$router.push('/mineIndex/17')" class="topRight">我要入驻</div>
						</div>
						<div class="takeItem">
							<div class="gotArea">{{item.districtName}}
								<span v-if="item.spannedName != null" class="serverSide">{{item.spannedName}}</span>
							</div>
							<div class="tagList">
								<!-- <span class="tagItem">{{item.tradeType_dictText}}</span> -->
								<span class="tagItem" v-if="item.tradeType == 1">邮寄交易</span>
								<span class="tagItem" v-if="item.tradeType == 2">拍卖交易</span>
								<span class="tagItem" v-if="item.tradeType ==3">摆摊交易</span>
								<span class="tagItem" v-if="item.tradeType == 4">邮当面交易</span>
							</div>
							<div class="discount">
								1元={{(item.piecesNum).toFixed(4)}}{{item.commodityUnit}}
							</div>
							<div class="discount1">1{{item.commodityUnit}} =
								{{( 1 / item.piecesNum  ).toFixed(2)}}/元
							</div>
							<div class="collectNumber" style="margin-top: 24px;">收货数量：<span
									style="color: #FF5C00;">{{item.num}}</span>{{item.commodityUnit}}
							</div>
							<div class="inputNumber">
								<input @input="($event)=>setTitle1($event,index)" v-prevent-space type="number"
									v-model="item.value" />
								<span class="unit">{{item.commodityUnit}}</span>
							</div>
							<div class="collectNumber">获得金额：<span
									style="color: #FF5C00;">{{ (item.value *  1 / item.piecesNum   || 0).toFixed(2)}}</span>元
							</div>
							<button class="sellSubmit" @click="sellOrder(item,index)">立即出售</button>
						</div>
					</div>


				</el-carousel-item>
				<!-- <div class="empty" v-if="receiveList.length == 0">
					<p>黄金展位</p>
					<p>欢迎入驻</p>
					<el-button @click="$router.push('/mineIndex/17')"
						style="background-color: #FF5B01;color: #fff;">立即入驻</el-button>
				</div> -->

			</el-carousel>
			<!-- <div v-if="receiveList.length != 0" class="seeAll" style="margin-bottom: 10px;" @click="toGameList('take')">
				查看全部
				<i class="el-icon-arrow-down"></i>
			</div> -->
		</div>
		<div class="lableTop" style="background-color: #F8F8F8;">

			<el-row class="content">

				<el-col :span="24">
					<div class="screen" ref="myElement">
						<div class="mainTop fl">
							<span class="topItem itemAction">填写商品信息</span>
						</div>
						<div class="hint" v-if="info.tradeRemark !='' && info.tradeRemark !=null">
							<div v-html="info.tradeRemark"></div>
						</div>
						<div class="fl" style="justify-content: space-between;padding:0 20px;"
							v-if="info.isDelivery == 1 && info.addType != 'addHot'">

							<div class="deliverItem fl" :class="{'tipAction':addForm.deliveryType == 1}"
								@click="addForm.deliveryType = 1">
								<img v-if="addForm.deliveryType == 1"
									:src="require('@/static/image/index/deliverGoods1.png')"
									style="width: 50px;height: 50px;" />
								<img v-if="addForm.deliveryType == 2"
									:src="require('@/static/image/index/deliverGoods.png')"
									style="width: 50px;height: 50px;" />
								<div class="deliverText">
									<span class="deliverTitle">卖家发货-无须填写游戏账号信息</span>
									<span class="deliverDetaile">任何交易中出现的异常情况！请您务必联系客服核实，减少规避损失！</span>
								</div>
								<div class="tips" :class="{'textAction':addForm.deliveryType == 1}">担保交易</div>
							</div>
							<div class="deliverItem fl " :class="{'tipAction':addForm.deliveryType == 2}"
								@click="addForm.deliveryType = 2">
								<img v-if="addForm.deliveryType == 1"
									:src="require('@/static/image/index/deliverGoods2.png')"
									style="width: 50px;height: 50px;" />
								<img v-if="addForm.deliveryType == 2"
									:src="require('@/static/image/index/deliverGoods3.png')"
									style="width: 50px;height: 50px;" />
								<div class="deliverText">
									<span class="deliverTitle">平台代发-需要填写游戏账号信息</span>
									<span
										class="deliverDetaile">商品被购买后，平台客服会登录账号，替您和买家完成交易。我们承诺您的账号资料经过5星加密保存，保障您的账号安全！</span>
								</div>
								<div class="tips " :class="{'textAction':addForm.deliveryType == 2}">寄售交易</div>
							</div>

						</div>

						<!-- <div class="formItem "
							v-if="info.gameCommodityCategory != '8' && info.gameCommodityCategory != '2'">
							<div class="item1" ><span style="color:#FF3C3C;">*</span>交易方式：</div>
							<div class="item2" style="white-space: nowrap;">
								<div>
									<el-radio v-model="addForm.tradeType" :label="1">邮寄交易</el-radio>
									<el-radio v-model="addForm.tradeType" :label="2">拍卖交易</el-radio>
									<el-radio v-model="addForm.tradeType" :label="3">摆摊交易</el-radio>
									<el-radio v-model="addForm.tradeType" :label="4">当面交易</el-radio>
								</div>
							</div>
						</div> -->
						<!-- 游戏币发布 -->
						<div v-if="info.gameCommodityCategory == '1' ||  info.gameCommodityCategory == '12'">
							<div class="submitForm " style="flex-wrap: wrap;">
								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>数量：</div>
									<div class="item2" style="width: 246px;">
										<el-input v-prevent-space type="number" @input="setTitle"
											v-model="addForm.piecesNum" placeholder="请输入数量"></el-input>
									</div>
									<span style="margin-left: 10px;">{{info.commodityUnit}}</span>
								</div>
								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>价格：</div>
									<div class="item2" style="width: 246px;">
										<el-input v-prevent-space @input="setTitle" v-model="addForm.price"
											placeholder="请输入价格"></el-input>
									</div>
									<span style="margin-left: 10px;">元</span>
								</div>
								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>发布件数：</div>
									<div class="item2" style="width: 246px;">
										<el-input type="number" @input="numInput" v-prevent-space v-model="addForm.num"
											placeholder="请输入数量"></el-input>
									</div>
									<span style="margin-left: 10px;">件</span>
								</div>
								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>商品标题：</div>
									<div class="item2" style="width: 600px;">
										<el-input disabled v-prevent-space v-model="addForm.title" style="width: 600px;"
											placeholder="请输入商品标题"></el-input>
									</div>
								</div>

								<div class="formItem fl" v-for="(item,index) in shopForm">
									<div class="item1"><span v-if="item.isRequired == 1"
											style="color:#FF3C3C;">*</span>{{item.name}}：</div>

									<div class="item2">
										<el-input v-prevent-space v-prevent-space v-if="item.form == 1"
											v-model="item.value" :placeholder="item.inputTip"></el-input>
										<el-select v-if="item.form == 3" style="width: 400px;" v-model="item.value"
											:popper-append-to-body="false" :placeholder="item.inputTip">
											<el-option v-for="(items,indexs) in item.options.split(' ')" :key="items"
												:label="items" :value="items">
											</el-option>
										</el-select>
										<div v-if="item.form == 2">
											<el-radio v-model="item.value" :label="items"
												v-for="(items,indexs) in item.options.split(' ')">{{items}}</el-radio>
										</div>
									</div>
									<div v-if="item.remark != ''" class="item3">{{item.remark}}</div>
								</div>
								<div class="formItem fl" v-if="addForm.deliveryType == 1">
									<div class="item1"><span style="color:#FF3C3C;">*</span>出货账号：</div>
									<div class="item2" style="width: 246px;">
										<el-input v-prevent-space v-prevent-space v-model="addForm.sellAccount"
											placeholder="请输入出货账号"></el-input>
									</div>
								</div>

								<div class="formItem fl" v-if="addForm.deliveryType == 1">
									<div class="item1"><span style="color:#FF3C3C;">*</span>出货角色：</div>
									<div class="item2" style="width: 246px;">
										<div class="role">
											<el-select no-data-text="暂无历史角色，请键入您的角色名"
												:filter-method="$event=>sellRoleFilter($event)" filterable allow-create
												:reserve-keyword="true" ref="searchSelect" style="width: 400px;"
												:popper-append-to-body="false" v-model="addForm.sellRole"
												placeholder="请输入角色">
												<el-option v-for="(items,indexs) in roleList" :key="items.receiveRole"
													:label="items.receiveRole" :value="items.receiveRole">
												</el-option>
											</el-select>
										</div>

									</div>
								</div>
								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>您的联系电话：</div>
									<div class="item2" style="width: 246px;">
										<el-input v-prevent-space v-prevent-space v-model="addForm.sellerPhone"
											placeholder="请输入您的联系电话"></el-input>
									</div>
								</div>
								<!-- <div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>您的联系QQ：</div>
									<div class="item2" style="width: 246px;">
										<el-input v-prevent-space v-prevent-space v-model="addForm.sellerQq"
											placeholder="请输入您的联系QQ"></el-input>
									</div>
								</div> -->
							</div>
						</div>
						<!-- 代练发布 -->
						<div v-else-if="info.gameCommodityCategory == '8' || info.gameCommodityCategory == '2'">

							<div class="formItem fl" v-if="info.gameCommodityCategory == 8">
								<div class="item1"><span style="color:#FF3C3C;">*</span>您需要的代练内容：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.title" placeholder="请输入代练内容"></el-input>
								</div>
							</div>
							<div class="formItem fl" v-if="info.gameCommodityCategory == 2">
								<div class="item1"><span style="color:#FF3C3C;">*</span>代练套餐：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.title" placeholder="请输入代练套餐"></el-input>
								</div>
							</div>

							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>代练备注：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.detail" type="textarea" :rows="6"
										placeholder="请输入代练备注描述,切勿填写与本商品无关的广告信息和联系方式"></el-input>
								</div>
							</div>



							<div class="formItem">
								<div style="display: flex;">
									<div class="item1"><span style="color:#FF3C3C;">*</span>
										<span v-if="info.gameCommodityCategory == 8">代练角色情况截图：</span>
										<span v-if="info.gameCommodityCategory == 2">相关截图：</span>
									</div>
									<div>
										<div class="item3" style="color: #FF5C00;margin-left: 0;margin-bottom: 12px;">
											代练截图能够有效增加代练关注度，代练列表拥有<span
												style="color: #0085FF;">“有图”</span>标识，大幅提升订单成交率，强烈建议上传截图。</div>
										<div class="item4">
											<div style="margin: 20px;">
												<el-upload accept=".jpg,.jpeg,.png"
													action="https://api.cc007.com/jeecg-boot/sys/common/upload"
													list-type="picture-card" :on-success="handlePictureCardPreview"
													:on-remove="handleRemove" :on-error="handleError" ref="uploadFile">
													<i class="el-icon-plus"></i>
												</el-upload>
											</div>
											<div class="item5">单张图片需小于10M，不能带有角色名、联系方式、广告等违规信息。</div>
										</div>
									</div>
								</div>
							</div>
							<div class="formItem fl" v-for="(item,index) in shopForm">
								<div class="item1"><span v-if="item.isRequired == 1"
										style="color:#FF3C3C;">*</span>{{item.name}}：</div>

								<div class="item2">
									<el-input v-prevent-space v-if="item.form == 1" v-model="item.value"
										:placeholder="item.inputTip"></el-input>
									<el-select v-if="item.form == 3" style="width: 400px;" v-model="item.value"
										:popper-append-to-body="false" :placeholder="item.inputTip">
										<el-option v-for="(items,indexs) in item.options.split(' ')" :key="items"
											:label="items" :value="items">
										</el-option>
									</el-select>
									<div v-if="item.form == 2">
										<el-radio style="margin-bottom: 10px;" v-model="item.value" :label="items"
											v-for="(items,indexs) in item.options.split(' ')">{{items}}</el-radio>
									</div>
									<el-select :filter-method="$event=>shopFormFilter($event,index)" filterable
										allow-create v-if="item.form == 5 " :reserve-keyword="true" ref="searchSelect"
										style="width: 400px;" :popper-append-to-body="false" v-model="item.value"
										:placeholder="item.inputTip">
										<el-option v-for="(items,indexs) in roleList" :key="items.receiveRole"
											:label="items.receiveRole" :value="items.receiveRole">
										</el-option>
									</el-select>
								</div>
								<div v-if="item.remark != ''" class="item3">{{item.remark}}</div>
							</div>

							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>您的联系电话：</div>
								<div class="item2" style="width: 246px;">
									<el-input v-prevent-space v-prevent-space v-model="addForm.sellerPhone"
										placeholder="请输入您的联系电话"></el-input>
								</div>
							</div>
							<!-- <div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>您的联系QQ：</div>
								<div class="item2" style="width: 246px;">
									<el-input v-prevent-space v-prevent-space v-model="addForm.sellerQq"
										placeholder="请输入您的联系QQ"></el-input>
								</div>
							</div> -->
						</div>
						<!-- 普通发布 -->
						<div v-else>

							<div class="formItem fl" v-for="(item,index) in shopForm">
								<div class="item1"><span v-if="item.isRequired == 1"
										style="color:#FF3C3C;">*</span>{{item.name}}：</div>

								<div class="item2">
									<el-input v-prevent-space v-prevent-space v-if="item.form == 1" v-model="item.value"
										:placeholder="item.inputTip"></el-input>
									<el-select v-if="item.form == 3" style="width: 400px;" v-model="item.value"
										:popper-append-to-body="false" :placeholder="item.inputTip">
										<el-option v-for="(items,indexs) in item.options.split(' ')" :key="items"
											:label="items" :value="items">
										</el-option>
									</el-select>
									<div v-if="item.form == 2">
										<el-radio style="margin-bottom: 10px;" v-model="item.value" :label="items"
											v-for="(items,indexs) in item.options.split(' ')">{{items}}</el-radio>
									</div>
								</div>
								<div v-if="item.remark != ''" class="item3">{{item.remark}}</div>
							</div>



							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>商品标题：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.title" placeholder="请输入商品标题"></el-input>
								</div>
							</div>
							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>商品描述：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.detail" type="textarea" :rows="6"
										placeholder="请输入商品描述,切勿填写与本商品无关的广告信息和联系方式"></el-input>
								</div>
							</div>

							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>商品价格：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.price" type="number"
										placeholder="请输入商品价格"></el-input>
								</div>
							</div>
							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>商品库存：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.num" type="number"
										placeholder="请输入商品库存"></el-input>
								</div>
							</div>
							<div class="formItem">
								<div style="display: flex;">
									<div class="item1"><span style="color:#FF3C3C;">*</span>商品截图：</div>
									<div>
										<div class="item3" style="color: #FF5C00;margin-left: 0;margin-bottom: 12px;">
											商品截图能够有效增加商品关注度，商品列表拥有<span
												style="color: #0085FF;">“有图”</span>标识，大幅提升订单成交率，强烈建议上传截图。</div>
										<div class="item4">
											<div style="margin: 20px;">
												<el-upload accept=".jpg,.jpeg,.png"
													action="https://api.cc007.com/jeecg-boot/sys/common/upload"
													list-type="picture-card" :on-success="handlePictureCardPreview"
													:on-remove="handleRemove" :on-error="handleError" ref="uploadFile">
													<i class="el-icon-plus"></i>
												</el-upload>
											</div>
											<div class="item5">单张图片需小于50M，不能带有角色名、联系方式、广告等违规信息。</div>
										</div>
									</div>
								</div>
							</div>

							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>您的联系电话：</div>
								<div class="item2" style="width: 246px;">
									<el-input v-prevent-space v-model="addForm.sellerPhone"
										placeholder="请输入您的联系电话"></el-input>
								</div>
							</div>
							<!-- <div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>联系QQ：</div>
								<div class="item2" style="width: 246px;">
									<el-input v-prevent-space v-model="addForm.sellerQq"
										placeholder="请输入联系QQ"></el-input>
								</div>
							</div> -->



						</div>

					</div>

					<div class="screen" style="margin-top: 0;"
						v-if="info.gameCommodityCategory == 8 || info.gameCommodityCategory == 2 ">
						<div class="mainTop fl">
							<span class="topItem itemAction">设置订单金额</span>
						</div>

						<div class="submitForm">
							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>设置代练保证金：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.trainDeposit" @change="minChange"
										:min="1" type="number" placeholder="请输入代练保证金"></el-input>
								</div>
							</div>
							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>设置订单佣金：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.price" type="number"
										placeholder="请输入订单佣金"></el-input>
								</div>
							</div>

							<div class="formItem fl">
								<div class="item1"><span style="color:#FF3C3C;">*</span>代练时间(小时)：</div>
								<div class="item2">
									<el-input v-prevent-space v-model="addForm.dailianTime" type="number"
										placeholder="请输入代练时间"></el-input>
								</div>
							</div>

						</div>

					</div>
					<!-- info.gameCommodityCategory != '1' && info.gameCommodityCategory != '7' && -->
					<div class="screen" style="margin-top: 0;"
						v-if="accountList.length != 0 && addForm.deliveryType == 2 &&(info.gameCommodityCategory == '1' || info.gameCommodityCategory == '12' ||  info.gameCommodityCategory == '13') ">
						<div class="mainTop fl">
							<span class="topItem itemAction">填写账号信息</span>
						</div>

						<div class="submitForm">
							<div class="formItem fl" v-for="(item,index) in accountList">
								<div class="item1"><span v-if="item.isRequired == 1"
										style="color:#FF3C3C;">*</span>{{item.name}}：</div>

								<div class="item2">
									<el-input v-prevent-space v-if="item.form == 1" v-model="item.value"
										:placeholder="item.inputTip"></el-input>
									<el-select v-if="item.form == 3" style="width: 400px;"
										:popper-append-to-body="false" v-model="item.value"
										:placeholder="item.inputTip">
										<el-option v-for="(items,indexs) in item.options.split(' ')" :key="items"
											:label="items" :value="items">
										</el-option>
									</el-select>
									<div v-if="item.form == 2">
										<el-radio v-model="item.value" :label="items"
											v-for="(items,indexs) in item.options.split(' ')">{{items}}</el-radio>
									</div>

									<el-select :filter-method="$event=>dataFilter($event,index)" filterable allow-create
										v-if="item.form == 5 " :reserve-keyword="true" ref="searchSelect"
										style="width: 400px;" :popper-append-to-body="false" v-model="item.value"
										:placeholder="item.inputTip">
										<el-option v-for="(items,indexs) in roleList" :key="items.receiveRole"
											:label="items.receiveRole" :value="items.receiveRole">
										</el-option>
									</el-select>

								</div>
								<div v-if="item.remark != ''" class="item3">{{item.remark}}</div>
							</div>

						</div>

					</div>

					<div class="screen" style="margin-top: 0;"
						v-if="accountList.length != 0 && info.gameCommodityCategory != '1' && info.gameCommodityCategory != '12' && info.gameCommodityCategory != '13' ">
						<div class="mainTop fl">
							<span class="topItem itemAction">填写账号信息</span>
						</div>

						<div class="submitForm">
							<div class="formItem fl" v-for="(item,index) in accountList">
								<div class="item1"><span v-if="item.isRequired == 1"
										style="color:#FF3C3C;">*</span>{{item.name}}：</div>

								<div class="item2">
									<el-input v-prevent-space v-if="item.form == 1" v-model="item.value"
										:placeholder="item.inputTip"></el-input>
									<el-select v-if="item.form == 3" style="width: 400px;"
										:popper-append-to-body="false" v-model="item.value"
										:placeholder="item.inputTip">
										<el-option v-for="(items,indexs) in item.options.split(' ')" :key="items"
											:label="items" :value="items">
										</el-option>
									</el-select>
									<div v-if="item.form == 2">
										<el-radio v-model="item.value" :label="items"
											v-for="(items,indexs) in item.options.split(' ')">{{items}}</el-radio>
									</div>

									<el-select :filter-method="$event=>dataFilter($event,index)" filterable allow-create
										v-if="item.form == 5 " :reserve-keyword="true" ref="searchSelect"
										style="width: 400px;" :popper-append-to-body="false" v-model="item.value"
										:placeholder="item.inputTip">
										<el-option v-for="(items,indexs) in roleList" :key="items.receiveRole"
											:label="items.receiveRole" :value="items.receiveRole">
										</el-option>
									</el-select>

								</div>
								<div v-if="item.remark != ''" class="item3">{{item.remark}}</div>
							</div>

						</div>

					</div>






					<div class="screen" style="margin-top: 0;"
						v-if="info.gameCommodityCategory != 8 && info.gameCommodityCategory != 2 && insureList.length != 0">
						<div class="mainTop fl">
							<span class="topItem itemAction">选择交易安全险</span>
						</div>

						<div class="formItem" style="margin-top: 50px;">
							<div class="item1" style="color:#FF3C3C;"><span style="color:#FF3C3C;">*</span>赠送交易安全险：
							</div>
							<div>
								<div class="secureList">
									<div v-for="(item,index) in insureList" @click="insureIndex = index"
										class="secureItem " :class="{'secureAction':insureIndex === index}">
										<div v-if="insureIndex === index" class="secureTopAction">保障升级，购赠永久包赔，让交易无忧
										</div>

										<div>
											<span style="font-size: 16px;margin-right: 5px;"
												v-if="item.days == '180'">享普通包赔</span>
											<span style="font-size: 16px;margin-right: 5px;"
												v-else-if="item.days == '360'">享进阶包赔</span>
											<span style="font-size: 18px;margin-right: 5px;" v-else>享普通包赔</span>

										</div>
										<div style="display: flex;flex-direction: column;margin-left: 15px;">
											<span
												style="font-size: 16px;margin-bottom: 5px;">￥{{getInsurePrice(item.rate)}}</span>
											<span
												style="font-size: 16px;opacity: .7;margin-bottom: 5px;">赔付百分比:{{item.paidRate}}%</span>
											<span
												style="font-size: 16px;opacity: .7;margin-bottom: 5px;">保障额度:{{getInsurePrice(item.paidRate)}}</span>
										</div>
										<!-- <span style="font-size: 18px;margin-right: 5px;">{{item.days}}天服务期</span>
										<span style="font-size: 16px;">{{getInsurePrice(item.rate)}}</span> -->
									</div>

									<div @click="insureIndex = ''" class="secureItem "
										:class="{'secureAction':insureIndex === ''}"
										style="padding: 15px;text-align: center;">
										<span style="text-align: center;margin-left: 60px;font-size: 16px;">不赠送保险</span>

										<!-- <span>{{item.rate}}%</span> -->
									</div>

								</div>
								<div class="secureTip">赠送保险的商品会被优先高亮展示</div>
								<div class="secureTip1">
									<span>1、购买交易安全险的商品带有"永久包赔","卖家投保"标识，商品更受欢迎</span>
									<span>2、"账号交易"购买保险服务后，"永久包赔"生效，若出现被卖家恶意找回等情况，平台核实后先行赔付给买家</span>
									<span>3、"账号交易"订单，购买保险后，交易双方必须签署交易合同，发生找回情况，平台法务将第一时间联合警方进行线下追回</span>
									<span>4、"金币交易"订单，购买保险后，若发生被官方回收扣除情况，平台将核实后全额赔付</span>
									<span>5、"代练交易"订单，购买保险后，若发生被盗，封号等情况，平台核实后将全额赔付</span>
								</div>
								<div class="secureTip2">
									<span>本模块为保险投保页面，选择购买表示您已阅读，理解并同意</span>
									<span style="color: #0085FF;"
										@click="$router.push('/serviceCenter/3/1808436948773982209')">《中国平安购买须知》</span>
									<span style="color: #0085FF;"
										@click="$router.push('/serviceCenter/2/1808234874761199618')">《什么是交易安全险》</span>
									<span style="color: #0085FF;"
										@click="$router.push('/serviceCenter/3/0')">《隐私政策》</span>
									<span style="color: #0085FF;"
										@click="$router.push('/serviceCenter/3/1808435680399675394')">《保险经纪委托书》</span>
									<span style="color: #0085FF;"
										@click="$router.push('/serviceCenter/3/0')">《客户告知书》</span>
									<span>。为保障您的权益将会安全记录您的操作</span>
								</div>
							</div>

						</div>

					</div>


					<div class="screen" style="margin-top: 0;">
						<div class="mainTop fl">
							<span class="topItem itemAction">填写交易信息</span>
						</div>

						<div class="formItem ">
							<div class="item1"
								v-if="info.gameCommodityCategory != 8 && info.gameCommodityCategory != 2"><span
									style="color:#FF3C3C;">*</span>指定买家购买：</div>
							<div class="item1" v-else><span style="color:#FF3C3C;">*</span>指定代练接单：</div>
							<div class="item2">
								<div>
									<el-radio v-model="addForm.designatedBuyer" :label="1">是</el-radio>
									<el-radio v-model="addForm.designatedBuyer" :label="0">否</el-radio>
								</div>
								<div class="item3"
									style="color: #A5A7B1;white-space: nowrap;margin-top: 10px;margin-left: 0;">
									发布成功后，商品不在商品列表公开展示，您需要分享商品ID给买家，买家搜索您的商品ID进行购买。
								</div>
							</div>
						</div>

						<!-- <div class="formItem "
							v-if="info.gameCommodityCategory != 8 && info.gameCommodityCategory != 2">
							<div class="item1"><span style="color:#FF3C3C;">*</span>允许求降价：</div>
							<div class="item2">
								<div>
									<el-radio v-model="addForm.priceReduction" :label="1">允许</el-radio>
									<el-radio v-model="addForm.priceReduction" :label="0">不允许</el-radio>
								</div>
								<div class="item3"
									style="color: #A5A7B1;white-space: nowrap;margin-top: 10px;margin-left: 0;">
									允许求降价后，在商品详情显示求降价按钮，买家可以向卖家发起求降价请求，如您发布的商品为一口价，请关闭允许求降价
								</div>
							</div>
						</div> -->
						<div class="formItem "
							v-if="info.gameCommodityCategory != 8 && info.gameCommodityCategory != 2">
							<div class="item1"><span style="color:#FF3C3C;">*</span>押金赔付：</div>
							<div class="item2">
								<div>
									<el-radio v-model="addForm.deposit" :label="1">是</el-radio>
									<el-radio v-model="addForm.deposit" :label="0">否</el-radio>
								</div>
								<div class="item3 fl"
									style="color: #A5A7B1;white-space: nowrap;margin-top: 10px;margin-left: 0;">
									<div v-if="addForm.deposit == 1">押金 <el-input v-prevent-space type="number"
											v-model="addForm.depositMoney" @change="setDeposit"
											style="width: 80px;"></el-input>
										元/件，</div>
									商品拥有押金赔付标识，商品优先排序，订单由于卖家原因取消将押金赔付给买家。
								</div>
							</div>
						</div>
						<div class="formItem ">
							<div class="item1"><span style="color:#FF3C3C;">*</span>商品有效期：</div>
							<div class="item2">
								<el-select style="width: 400px;" :popper-append-to-body="false"
									v-model="addForm.expirationNum" placeholder="请选择有效期">
									<el-option v-for="(items,indexs) in validityList" :key="items" :label="items.value"
										:value="items.key">
									</el-option>
								</el-select>

							</div>
						</div>

						<div class="formItem ">
							<div class="item1"><span style="color:#FF3C3C;">*</span>交易方便时间：</div>
							<div class="item2">
								<div class="fl" style="position: relative;">
									<el-time-select :append-to-body="false" ref="pageSelect" v-model="addForm.startTime"
										popper-class="popper-select" :picker-options="{
										    start: '00:00',
										    step: '00:15',
										    end: '24:00'
										  }" placeholder="选择时间">
									</el-time-select>


									<div>&emsp;-&emsp;</div>
									<el-time-select ref="pageSelect1" v-model="addForm.endTime"
										popper-class="popper-select" :append-to-body="false" :picker-options="{
										    start: '00:00',
										    step: '00:15',
										    end: '24:00'
										  }" placeholder="选择时间">
									</el-time-select>
								</div>
							</div>
						</div>

					</div>
				</el-col>

			</el-row>
		</div>
		<div class="issue">
			<div @click="$noMultipleClicks(publish)">
				<el-button style="font-size: 20px;" type="primary">确认无误，立即发布
					<span v-if="totalPrice() != '0.00'">({{totalPrice()}}元)</span>
				</el-button>
			</div>

			<div class="issueText" v-if="info.gameCommodityCategory == 2 ">
				点击确认发布表示已阅读并同意<span style="color: #0085FF;"
					@click="$router.push('/serviceCenter/3/1804449768665804802')">《CC网络代练协议(工作室方)》</span>
			</div>
			<div class="issueText" v-else-if="info.gameCommodityCategory == 8">
				点击确认发布表示已阅读并同意<span style="color: #0085FF;"
					@click="$router.push('/serviceCenter/3/1804450134736269313')">《CC网络代练交易协议(玩家)》</span>
			</div>
			<div class="issueText" v-else>
				点击确认发布表示已阅读并同意<span style="color: #0085FF;"
					@click="$router.push('/serviceCenter/3/1806709180419907585')">《卖家出售商品服务协议》</span>
			</div>
		</div>
		<footer-bars></footer-bars>
		<shopping-card></shopping-card>
	</div>
</template>

<script>
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import footerBars from '@/components/footerBars.vue';
	import {
		accountList,
		fieldList,
		insureList,
		gameCommodityAdd,
		receiveRoleList,
		receiveGoods,
		realInfo
	} from '@/api/index.js'
	export default {
		components: {
			layoutTop,
			shoppingCard,
			footerBars
		},
		data() {
			return {
				mainsTop: 443,
				// 角色列表
				roleList: [],
				step: 3,
				radio: 2,
				// deliveType: 0,
				insureList: [],
				accountList: [],
				insureIndex: '',
				validityList: [{
						key: 30,
						value: '30天'
					},
					{
						key: 60,
						value: '60天'
					},
					{
						key: 90,
						value: '90天'
					},
					{
						key: 120,
						value: '120天'
					},
					{
						key: 150,
						value: '150天'
					},
					{
						key: 180,
						value: '180天'
					}
				],



				shopForm: [],
				// 是否赠送
				isGive: 1,
				addImage: [],
				addForm: {
					dailianTime: '',
					detail: '',
					// 发货类型
					deliveryType: 1,
					// 押金
					deposit: 0,
					// 押金类型 上个页面传过来
					depositMoney: 10,
					// 指定买家
					designatedBuyer: 0,
					//交易方便开始时间
					startTime: '00:00',
					//交易方便结束时间
					endTime: '24:00',
					// 保险天数
					days: '',
					// 保险比例
					rate: '',
					insurePaidRate: '',
					// 交易方式
					tradeType: 1,
					// 标题
					title: '',
					// 价格
					price: '',
					// 图片
					pic: '',
					// 库存
					num: '1',
					// 代练保证金
					trainDeposit: 100,
					//允许降价
					priceReduction: 0,
					// 商品有效期
					expirationNum: 30,
					// 游戏账号信息	
					gameCommodityAccountInfos: [],
					gameCommodityFieldValues: {},
					piecesNum: '',
					// 子分类
					childCommodityType: '',
					// 游戏商品分类id	
					commodityTypeId: '',
					// 游戏商品分类名称	
					commodityTypeName: '',
					// 区服id
					districtId: '',
					// 区服名称
					districtName: '',
					// 游戏商品大类
					gameCommodityCategory: '',
					isSendInsure: '',
					// 游戏ID
					gameId: '',
					// 游戏图标
					icon: '',
					userId: '',
					isHot: 0,
					isOffShelf: 0,
					// 收货账号
					sellAccount: '',
					// 收货角色
					sellRole: '',
					// 卖家手机号
					sellerPhone: '',
					// 卖家qq
					sellerQq: '',


				},
				rulesForm: {
					data: []
				},
				info: {},
				isShow: false,
				receiveList: [],
				receiveSearch: {
					commodityTypeId: '',
					gameId: '',
					isOffShelf: 0,
					districtId: '',
					pageNo: 1,
					pageSize: 2,
					title: '',
				},
				//* 在data中创建一个noClick默认启动
				noClick: true,
			}
		},
		created() {
			// if (localStorage.getItem('confirmOrderQQ') != null) {
			// 	this.addForm.sellerQq = localStorage.getItem('confirmOrderQQ')
			// } else {
			// 	if (!this.isEmpty(this.$store.state.userInfo.qq)) {
			// 		this.addForm.sellerQq = this.$store.state.userInfo.qq
			// 	}

			// }
			if (localStorage.getItem('confirmOrderPhone') != null) {
				this.addForm.sellerPhone = localStorage.getItem('confirmOrderPhone')
			} else {
				if (!this.isEmpty(this.$store.state.userInfo.account)) {
					this.addForm.sellerPhone = this.$store.state.userInfo.account
				}

			}
			this.info = JSON.parse(this.$route.query.info)
			console.log(this.info)
			if (JSON.parse(this.$route.query.info) == null) {
				this.$router.push('/')
			}

			this.getAccountList()
			this.isShow = true

			this.receiveSearch.gameId = this.info.gameId
			this.receiveSearch.commodityTypeId = this.info.commodityTypeId
			this.receiveSearch.districtId = this.info.districtId
			this.getReceiveGoods()
			this.getReceiveRoleList()


		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.checkElementPosition);
		},
		mounted() {

			window.addEventListener('scroll', this.checkElementPosition);
		},
		methods: {
			numInput() {
				if (this.addForm.num > 9999) {
					this.addForm.num = 9999
				}
				if (this.addForm.num < 1) {
					this.addForm.num = 1
				}
			},
			setTitle1(e, index) {
				this.receiveList[index].value = parseInt(this.receiveList[index].value)
			},
			checkElementPosition() {
				// 访问元素  
				const element = this.$refs.myElement;
				// 获取元素的边界信息  
				const rect = element.getBoundingClientRect();
				if (rect.top > 0) {
					this.mainsTop = rect.top
				} else {
					this.mainsTop = 0
				}
			},
			// 获取收货列表
			getReceiveGoods() {
				if (this.receiveSearch.commodityTypeId == '') {
					this.receiveSearch.commodityTypeId = null
				}
				receiveGoods(this.receiveSearch).then(res => {
					if (res.code == 200) {
						this.receiveList = res.result.records
						this.receiveTotal = res.result.total
					}

				})
			},
			// 获取角色列表
			getReceiveRoleList() {
				receiveRoleList({
					districtId: this.info.districtId,
					pageNo: '1',
					pageSize: '1000'
				}).then(res => {
					if (res.code == 200) {
						this.roleList = res.result.records
					}
				})
			},
			// 发布信息
			publish() {
				console.log(this.shopForm)
				console.log(this.accountList)
				if (this.info.addType == 'addHot') {
					this.addForm.isHot = 1
					this.addForm.isOffShelf = 1
				} else {
					this.addForm.isHot = 0
				}
				this.addForm.gameCommodityAccountInfos = []
				this.addForm.gameCommodityFieldValues = {}
				//  游戏币发布
				if (this.info.gameCommodityCategory == '1' || this.info.gameCommodityCategory == '12') {
					if (this.addForm.piecesNum == '' || this.addForm.piecesNum == null || this.addForm.piecesNum ==
						undefined) {
						this.message('请输入数量', 'error')
						return
					}
					if (this.addForm.price == '' || this.addForm.price == null || this.addForm.price == undefined) {
						this.message('请输入价格', 'error')
						return
					}
					if (this.addForm.num == '' || this.addForm.num == null || this.addForm.num == undefined) {
						this.message('请输入件数', 'error')
						return
					}
					if (this.addForm.title == '' || this.addForm.title == null || this.addForm.title == undefined) {
						this.message('请输入商品标题', 'error')
						return
					}
					if (this.addForm.deliveryType == 1) {
						if (this.addForm.sellRole == '' || this.addForm.sellRole == null || this.addForm.sellRole ==
							undefined) {
							this.message('请输入角色', 'error')
							return
						}
					}
					if (this.addForm.deliveryType == 1) {
						if (this.addForm.sellAccount == '' || this.addForm.sellAccount == null || this.addForm
							.sellAccount == undefined) {
							this.message('请输入出货账号', 'error')
							return
						}
					}
					if (this.addForm.sellerPhone == '' || this.addForm.sellerPhone == null || this.addForm.sellerPhone ==
						undefined) {
						this.message('请输入您的联系电话', 'error')
						return
					}
					// if (this.addForm.sellerQq == '' || this.addForm.sellerQq == null || this.addForm.sellerQq ==
					// 	undefined) {
					// 	this.message('请输入您的联系qq', 'error')
					// 	return
					// }

					//  代练发布
				} else if (this.info.gameCommodityCategory == '8' || this.info.gameCommodityCategory == '2') {
					if (this.info.gameCommodityCategory == '8') {
						if (this.addForm.title == '' || this.addForm.title == null || this.addForm.title == undefined) {
							this.message('请输入代练内容', 'error')
							return
						}
					}
					if (this.info.gameCommodityCategory == '2') {
						if (this.addForm.title == '' || this.addForm.title == null || this.addForm.title == undefined) {
							this.message('请输入代练套餐', 'error')
							return
						}
					}
					if (this.addForm.detail == '' || this.addForm.detail == null || this.addForm.detail == undefined) {
						this.message('请输入代练备注', 'error')
						return
					}

					if (this.addImage.length == 0) {
						this.message('请上传截图', 'error')
						return
					}

					if (this.addForm.sellerPhone == '' || this.addForm.sellerPhone == null || this.addForm.sellerPhone ==
						undefined) {
						this.message('请输入您的联系电话', 'error')
						return
					}
					// if (this.addForm.sellerQq == '' || this.addForm.sellerQq == null || this.addForm.sellerQq ==
					// 	undefined) {
					// 	this.message('请输入您的联系qq', 'error')
					// 	return
					// }
					if (this.addForm.trainDeposit == '' || this.addForm.trainDeposit == null || this.addForm
						.trainDeposit == undefined) {
						this.message('请输入代练保证金', 'error')
						return
					}
					if (this.addForm.price == '' || this.addForm.price == null || this.addForm.price == undefined) {
						this.message('请输入订单佣金', 'error')
						return
					}
					if (this.addForm.dailianTime == '' || this.addForm.dailianTime == null || this.addForm.dailianTime ==
						undefined) {
						this.message('请输入代练时间', 'error')
						return
					}

				} else {
					if (this.addForm.title == '' || this.addForm.title == null || this.addForm.title == undefined) {
						this.message('请输入商品标题', 'error')
						return
					}
					if (this.addForm.detail == '' || this.addForm.detail == null || this.addForm.detail == undefined) {
						this.message('请输入商品描述', 'error')
						return
					}
					if (this.addForm.price == '' || this.addForm.price == null || this.addForm.price == undefined) {
						this.message('请输入商品价格', 'error')
						return
					}
					if (this.addForm.num == '' || this.addForm.num == null || this.addForm.num == undefined) {
						this.message('请输入商品库存', 'error')
						return
					}
					if (this.addForm.sellerPhone == '' || this.addForm.sellerPhone == null || this.addForm.sellerPhone ==
						undefined) {
						this.message('请输入您的联系电话', 'error')
						return
					}

				}

				let isPublic = true
				if (this.shopForm.length != 0) {
					let newShopForm = []
					try {
						this.shopForm.forEach(item => {
							if (item.isRequired == 1) {
								if (item.value == '' || item.value == null || item.value == undefined) {
									this.message('请填写' + item.name, 'error')
									throw Error();
								}
							}

							let key = {
								iconKey: `field${item.fieldNum}Icon`,
								iconValue: item.icon,
								nameKey: `field${item.fieldNum}Name`,
								nameValue: item.name,
								valueKey: `field${item.fieldNum}Value`,
								valueValue: item.value
							}
							newShopForm.push(key)
						})
						this.addForm.gameCommodityFieldValues = newShopForm.reduce((obj, item) => {
							obj[item.iconKey] = item.iconValue
							obj[item.nameKey] = item.nameValue
							obj[item.valueKey] = item.valueValue
							return obj
						}, {})
					} catch (e) {
						isPublic = false

					}


				}

				if (!isPublic) {
					return
				}
				if (this.addForm.deliveryType == 2 && (this.info.gameCommodityCategory == '1' || this.info
						.gameCommodityCategory == '12' || this.info.gameCommodityCategory == '13')) {
					if (this.accountList.length != 0) {
						try {
							this.accountList.forEach(item => {
								if (item.isRequired == 1) {
									if (item.value == '' || item.value == null || item.value == undefined) {
										this.message('请填写' + item.name, 'error')
										throw Error();
									}
								}

								this.addForm.gameCommodityAccountInfos.push({
									content: item.value,
									sort: item.sort,
									title: item.name
								})
							})
						} catch (e) {
							isPublic = false

						}
					}
				}


				if (this.info.gameCommodityCategory != '1' && this.info.gameCommodityCategory != '12' && this.info
					.gameCommodityCategory != '13') {
					if (this.accountList.length != 0) {
						try {
							this.accountList.forEach(item => {
								if (item.isRequired == 1) {
									if (item.value == '' || item.value == null || item.value == undefined) {
										this.message('请填写' + item.name, 'error')
										throw Error();
									}
								}
								this.addForm.gameCommodityAccountInfos.push({
									content: item.value,
									sort: item.sort,
									title: item.name
								})
							})
						} catch (e) {
							isPublic = false

						}

					}
				}

				if (this.insureIndex === '') {
					this.isGive = 0
				} else {
					this.isGive = 1
				}



				this.addForm.childCommodityType = this.info.childCommodityType
				this.addForm.commodityTypeId = this.info.commodityTypeId
				this.addForm.commodityTypeName = this.info.commodityTypeName
				this.addForm.districtId = this.info.districtId
				this.addForm.districtName = this.info.districtName
				this.addForm.gameCommodityCategory = this.info.gameCommodityCategory
				this.addForm.gameId = this.info.gameId
				this.addForm.icon = this.info.icon
				this.addForm.isSendInsure = this.isGive
				this.addForm.pic = this.addImage.join(',')
				this.addForm.userId = this.$store.state.userInfo.id
				this.addForm.price = Number(this.addForm.price).toFixed('2')
				if (this.isGive == 1 && this.info.gameCommodityCategory != 8 && this.info.gameCommodityCategory != 2) {
					this.addForm.days = this.insureList[this.insureIndex].days
					this.addForm.rate = this.insureList[this.insureIndex].rate
					this.addForm.insurePaidRate = this.insureList[this.insureIndex].paidRate
				}
				if (this.addForm.deposit == 0) {
					this.addForm.singleDepositMoney = 0
				} else {
					this.addForm.singleDepositMoney = this.addForm.depositMoney
				}
				if (!isPublic) {
					return
				}

				setTimeout(() => {
					gameCommodityAdd(
						this.addForm
					).then(res => {
						if (res.code == 200) {
							if (res.result.status == 0) {
								if (res.result.gameCommodityCategory == 8 || res.result
									.gameCommodityCategory == 2) {
									let info = {
										title: res.result.title,
										districtName: res.result.districtName,
										price: res.result.price,
										id: res.result.id,
										type: 'earnestMoney',
										depositType: 8
									}
									if (res.result.gameCommodityCategory == 8) {
										info.depositType = 6
									} else {
										info.price = this.addForm.trainDeposit
									}
									info.createTime = this.sjCurrent(6)
									// localStorage.setItem('orderPay', JSON.stringify(info));
									this.$router.push({
										path: '/orderPay',
										query: {
											info: JSON.stringify(info)
										}
									})
								} else {
									let info = {
										title: res.result.title,
										districtName: res.result.districtName,
										price: this.totalPrice(),
										id: res.result.id,
										type: 'earnestMoney',
										depositType: 7
									}
									info.createTime = this.sjCurrent(6)
									// localStorage.setItem('orderPay', JSON.stringify(info));
									this.$router.push({
										path: '/orderPay',
										query: {
											info: JSON.stringify(info)
										}
									})
								}

							} else {
								this.message('发布成功', 'success')
								if (this.info.addType == 'addHot') {
									this.$router.push({
										path: '/mineIndex/20'

									})
								} else {
									// localStorage.setItem('orderPay', JSON.stringify(this.info));
									this.$router.push({
										path: '/publishSuccess',
										query: {
											info: JSON.stringify(this.info)
										}
									})
								}

							}


						}
					})
				}, 1000)


			},
			// 保险列表
			getInsureList() {
				insureList({
					gameTypeId: this.info.commodityTypeId
				}).then(res => {
					this.insureList = res.result
				})
			},
			// 计算总价
			totalPrice() {
				let total = 0
				if (this.addForm.deposit == 1) {
					total += Number(this.addForm.depositMoney) * Number(this.addForm.num)
				}
				// if(this.isGive == 1 && this.insureList.length != 0 ){
				// 	total = total +  (Number(this.addForm.price) * Number(this.addForm.num) * Number(this.insureList[this.insureIndex].rate) /100)
				// }

				return total.toFixed(2)
			},
			// 判断押金金额是否小于10 
			setDeposit(e) {
				if (this.addForm.depositMoney < 10) {
					this.addForm.depositMoney = 10
					this.message('押金最低为10元', 'error')
				}
			},
			getAccountList() {
				accountList({
					gameTypeId: this.info.commodityTypeId
				}).then(res => {
					if (res.code == 200) {
						this.accountList = res.result
						this.getFieldList()

					}

				})
			},
			getFieldList() {
				this.shopForm = []
				let that = this;
				fieldList({
					gameTypeId: this.info.commodityTypeId
				}).then(res => {
					if (res.code == 200) {
						this.shopForm = res.result

						this.rulesForm.data.push(res.result)
						this.$forceUpdate()
						that.getInsureList()
					}

				})
			},
			// 上传失败事件
			handleError(e) {
				this.$message('上传失败', 'error')
			},
			// 上传成功事件
			handlePictureCardPreview(e) {
				this.addImage.push(e.result)
			},
			// 删除图片
			handleRemove(file, fileList) {
				this.addImage = []
				fileList.forEach(item => {
					this.addImage.push(item.response.result)
				})
			},
			//设置最小值
			minChange() {
				if (Number(this.addForm.trainDeposit) < 1) {
					this.addForm.trainDeposit = 1
				}
			},
			setTitle() {
				this.addForm.piecesNum = parseInt(this.addForm.piecesNum)
				if (this.addForm.piecesNum != '' && this.addForm.price != '') {
					this.addForm.title = this.addForm.piecesNum + this.info.commodityUnit + '=' + Number(this.addForm
						.price).toFixed(2) + '元'
				}
			},
			// 处理弹出框位置不对

			getTop(e) {
				// 获取事件触发的元素距离可视窗口的顶部距离 + 元素自身的高度
				let top = e.$el.getBoundingClientRect().bottom + 30 + 'px'
				console.log(top)
				// 之所以使用定时器是因为，元素渲染需要一点时间
				setTimeout(() => {
					// 获取正在显示的下拉框
					let down = document.querySelector('div[x-placement]')
					console.log(down.style)
					// 设置定位为绝对定位（基于body）
					down.style.position = 'absolute'
					// 设置与顶部的距离
					down.style.bottom = top
					// 获取所有隐藏的下拉框
					let panel = document.querySelectorAll('.el-picker-panel')
					// 循环给所有隐藏下拉框更改样式
					for (var i = 0; i < panel.length; i++) {
						panel[i].style.position = 'absolute'
						panel[i].style.bottom = top
					}
				}, 200)

			},
			dataFilter(val, index) {
				this.$set(this.accountList[index], 'value', val)
			},
			shopFormFilter(val, index) {
				this.$set(this.shopForm[index], 'value', val)
			},
			sellRoleFilter(val) {
				this.$set(this.addForm, 'sellRole', val)
			},
			getInsurePrice(rate) {
				if (this.addForm.price * rate / 100 > this.info.minInsure) {
					return (this.addForm.price * rate / 100).toFixed(2) + '元'
				} else {
					return this.info.minInsure + '元'
				}
			},
			// 收货
			sellOrder(item, index) {
				console.log(item)
				// 判断是否实名
				realInfo().then(res => {
					if (res.code == 200) {
						if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
							this.message('未实名认证，请先实名认证', 'error')
							setTimeout(() => {
								this.$router.push('/setting/2')
							}, 1500)
						} else {
							if (item.value < item.minNum) {
								this.message(`单次出售不得低于${item.minNum}`, 'error')
								return
							}
							if (item.value > item.maxNum) {
								this.message(`单次出售不得高于${item.maxNum}`, 'error')
								return
							}
							let info = {
								id: item.id,
								numValue: item.value,
								// priceValue: item.priceValue,
								num: item.value,
								type: 'receiving'
							}
							// localStorage.setItem('confirmOrder', JSON.stringify(info));
							this.$router.push({
								path: '/confirmOrder',
								query: {
									info: JSON.stringify(info)
								}
							})
						}
					}
				})

			},
			change(e) {
				this.receiveSearch.pageNo = e + 1
				this.getReceiveGoods()
			},





		}
	}
</script>

<style lang="scss" scoped>
	.stepList {
		width: 600px;
		margin: 30px auto;
	}

	.step {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.step::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #DDDDDD;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepAction {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.stepAction::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #0085FF;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepSuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	::v-deep .el-step__title.is-process {
		color: #C0C4CC;
		font-weight: 500;

	}

	.lableTop {
		position: relative;

		.lableImage {
			width: 100%;
			height: 150px;
			position: absolute;
		}
	}

	.lableBg {
		padding-top: 20px;
		height: 180px;
		display: flex;

		.lableTitle {
			font-weight: bold;
			font-size: 24px;
			color: #FFFFFF;
		}

		.lableDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}

	.mainTop {
		border-bottom: 1px solid #ECECEC;

		.topItem {
			width: 184px;
			height: 66px;
			font-weight: 600;
			font-size: 16px;
			color: #0085FF;
			line-height: 66px;
			text-align: center;
		}

		.itemAction {
			border-bottom: 3px solid #0085FF;
		}
	}

	.screen {
		color: #777A8A;
		background: #FFFFFF;

		margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		margin-bottom: 20px;
		padding-bottom: 20px;
		// padding: 0 0 24px;
	}

	.hint {
		margin: 40px 20px;
		padding: 15px;
		// height: 300px;
		background: #FFFCF0;
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #FFCC33;
		font-weight: 500;
		font-size: 14px;
		color: #777A8A;
		line-height: 30px;
	}

	.deliverItem {
		width: 502px;
		height: 72px;
		background: #FAFAFA;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #DEDEDE;
		padding: 25px;
		position: relative;
		overflow: hidden;

		.deliverText {
			display: flex;
			flex-direction: column;
			width: 500px;
			margin-left: 27px;
		}

		.deliverTitle {

			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			line-height: 30px;

		}

		.deliverDetaile {

			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			line-height: 30px;

		}

		.tips {
			width: 164px;
			height: 32px;
			background: #DDDDDD;
			text-align: center;
			line-height: 32px;
			position: absolute;
			top: 20px;
			right: -50px;
			transform: rotate(45deg);
			color: #fff;
		}

		.textAction {
			background-color: #0085FF;
		}
	}

	.tipAction {
		border: 2px solid #0085FF;
		background: rgba(0, 133, 255, 0.04);
	}

	.payType {
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
		padding: 20px;
	}

	.payRadio {
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
		display: flex;
		align-items: center;
		margin-left: 30px;
	}

	::v-deep .el-radio__inner {
		width: 18px;
		height: 18px;
	}

	.submitForm {
		background: rgba(0, 133, 255, 0.02);
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #0085FF;
		padding-bottom: 20px;
		margin: 30px 20px 0;

	}

	.formItem {
		margin-top: 20px;
		color: #000;
		display: flex;

		.item1 {
			width: 400px;
			text-align: right;
		}

		.item2 {
			min-width: 400px;
			margin-left: 10px;
			position: relative;

			::v-deep .el-select-dropdown {
				position: absolute !important;

				top: 35px !important;
				left: 0px !important;
			}


		}

		.item3 {
			font-weight: 400;
			font-size: 16px;
			color: #0085FF;
			margin-left: 20px;
		}

		.item4 {
			border: 1px solid #DEDEDE;
			border-radius: 4px 4px 4px 4px;
		}

		.item5 {
			border-top: 1px solid #DEDEDE;
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
			height: 56px;
			line-height: 56px;
			padding-left: 20px;
		}

	}

	.issue {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #F8F8F8;
	}

	.issueText {
		font-weight: 400;
		font-size: 16px;
		color: #777A8A;
		margin: 20px 0 60px;
	}

	.secureList {
		display: flex;
		flex-wrap: wrap;
		width: 1000px;
		align-items: flex-end;

		.secureItem {
			width: 320px;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #DEDEDE;
			// text-align: center;
			// line-height: 80px;
			font-weight: 400;
			font-size: 22px;
			color: #1D223C;
			margin-bottom: 40px;
			margin-right: 16px;
			position: relative;
			display: flex;
			padding-top: 20px;
			padding-left: 20px;

		}

		.secureAction {
			border-color: #0085FF;
			border-width: 3px;
			color: #FF3C3C;
		}
	}

	.secureTip {
		font-weight: 400;
		font-size: 18px;
		color: #FF3C3C;
		line-height: 20px;
	}

	.secureTip1 {
		width: 696px;
		background: #FFFCF0;
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #FFCC33;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 30px 10px;
		margin-top: 24px;
	}

	.secureTip2 {
		width: 716px;
		font-weight: 400;
		font-size: 16px;
		color: #000000;
		margin-top: 16px;
	}

	.secureTopAction {
		width: 343px;
		position: absolute;
		top: -40px;
		height: 40px;
		left: -2px;
		background-color: #0085FF;
		text-align: center;
		color: #fff;
		border-radius: 6px 6px 0px 0px;
		line-height: 40px;
		font-size: 18px;
	}

	::v-deep .popper-select {
		position: absolute !important;
		top: auto !important;
		left: auto !important;

	}

	.hotMains {
		position: fixed;
		left: calc(50% - 950px + 80px);
		margin-left: 10px;
		z-index: 999;
		width: 250px;
		background: #FFFFFF;
		border: 1px solid #FF5C00;
		border-radius: 8px 8px 8px 8px;
	}

	.hotMain {
		width: 250px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;



		.mainLeft {
			height: 32px;
			background: #FFF4E8;
			padding: 16px;
			justify-content: space-between;
			border-radius: 8px 8px 0 0;
			font-weight: 600;
			font-size: 18px;
			color: #FF5C00;
		}

		.topRight {
			width: 88px;
			height: 33px;
			background: #FF5C00;
			border-radius: 6px 6px 6px 6px;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 21px;
			text-align: center;
			line-height: 33px;
			cursor: pointer;
		}

		.inputNumber {
			border: 1px solid #DEDEDE;
			width: 90%;
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
		}
	}

	.gotArea {
		font-weight: 600;
		font-size: 18px;
		color: #000000;
		text-align: center;
		margin-top: 24px;
	}

	.tagList {
		display: flex;
		justify-content: center;
		margin-top: 8px;

		.tagItem {
			font-weight: 500;
			font-size: 16px;
			color: #0085FF;
			padding: 1px 5px;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #0085FF;
			margin-right: 8px;
		}
	}

	.discount {
		font-weight: 600;
		font-size: 18px;
		color: #FF5C00;
		text-align: center;
		margin-top: 16px;
	}

	.discount1 {
		font-weight: 500;
		font-size: 16px;
		color: #777A8A;
		text-align: center;
		margin-top: 8px;
	}


	.collectNumber {
		margin: 10px 16px 8px;
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		line-height: 19px;

	}

	.inputNumber {
		border: 1px solid #DEDEDE;
		width: 249px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;

		input {
			border: none;
		}

		.unit {
			width: 74px;
			height: 46px;
			background: #F7F7F7;
			border-radius: 0px 4px 4px 0px;
			border: 1px solid #DEDEDE;
			text-align: center;
			line-height: 46px;

			font-weight: 600;
			font-size: 16px;
			color: #000000;
		}
	}

	.sellSubmit {
		width: 90%;
		height: 42px;
		background: #FF5C00;
		border-radius: 6px 6px 6px 6px;
		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		margin: 16px;
	}

	.role {
		::v-deep .el-input__suffix {
			display: none;
		}

		::v-deep .el-input__inner {
			cursor: text;
		}
	}
</style>