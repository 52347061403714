<template>
	<div v-if="isShow">
		<layout-top></layout-top>
		<div class="stepList">
			<el-steps :active="step" align-center>
				<el-step title="选择商品">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=1 }">1</div>
					</div>
				</el-step>
				<el-step title="结算订单">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=2 }">2</div>
					</div>
				</el-step>
				<el-step title="确认收货">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=3 }">3</div>
					</div>
				</el-step>
				<el-step title="交易完成">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=4 }">
							✔
						</div>
					</div>
				</el-step>
			</el-steps>
		</div>
		<div>
			
		</div>
		<div class="lableTop ">
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row class="content">
				
				<el-col :span="24">
					<div class="lableBg">
						<div class=" gameClass">
							<div class="lableTitle">确认订单</div>
							<div class="lableDetaile">网络游戏</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="lableTop">
			<el-row class="content">
				
				<el-col :span="24">
					<div class="screen">
						<div class="mainTop fl">
							<span class="topItem itemAction">确认订单信息</span>
						</div>
						<div class="thTable fl">
							<span class="table1">价格</span>
							<span v-if="info.type == 'hot'" class="table2">购买数量</span>
							<span v-else class="table2">{{shopList[0].gameCommodityCategory == 8 ? '保证金' : '单件价格'}}
							</span>
							<div class="table3 " v-if="info.type == 'lower'">
								<span class="must">降价后单价</span>
							</div>
							<span v-if="info.type == 'hot'" class="table4">商品比例</span>
							<span v-else-if="info.type == 'receiving'" class="table4">出售数量</span>
							<span v-else class="table4">购买数量</span>
							<span v-if="info.type == 'receiving'" class="table5">预估收益</span>
							<span v-else class="table5">{{shopList[0].gameCommodityCategory == 8 ? '保证金' : '金额'}}</span>
						</div>

						<div class="tableInfo fl" v-for="(item,index) in shopList">
							<div class="table1 ">
								<img v-if="item.icon != '' && item.icon != null" class="infoImage" :src="item.icon"
									alt="" />
								<div style="margin-left: 20px;">
									<div class="infoTitle el1">
										<span v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6"
											class="infoAccount">账户</span>
										<span>{{item.title}}</span>
									</div>
									<div class="idItem">
										<span>游戏区服：</span>
										<span style="color: #1D223C;">{{item.districtName}}</span>
									</div>
									<div class="idItem">
										<span>商品类型：</span>
										<span style="color: #1D223C;">{{item.commodityTypeName}}</span>
									</div>
									<div class="idItem fl" v-if="item.isSendInsure == 1">
										<img :src="require('@/static/image/index/bao.png')"
											style="width: 16px;height: 16px;" />
										<span style="color: #1D223C;margin-left: 5px;">赠永久包赔</span>
									</div>

								</div>
							</div>
							<div class="table2 " v-if="info.type == 'hot'">
								<div class="mustInput fl">
									<input v-prevent-space type="number" @input="setTitle" v-model="info.numValue" :min="info.minOrderNum"
										placeholder="请输入您的出价">
									<div class="suffix">{{item.commodityUnit}}</div>
								</div>

							</div>
							<span v-else
								class="table2">￥{{item.gameCommodityCategory == 8 ? item.totalPrice : item.price}}</span>

							<div class="table3 " v-if="info.type == 'lower'">
								<div class="mustInput fl">
									<input v-prevent-space type="number" style="font-size: 12px;" v-model="item.lowerValue"
										@change="$event=>minValue($event,index)" placeholder="请输入您的出价">
									<div class="suffix">元</div>
								</div>
								<div class="scope">出价范围：{{item.minPrice}}元-{{item.price}}元</div>
								<!-- <span class="must">降价后单价</span> -->
							</div>
							<div v-if="info.type == 'hot'" class="table4">
								<div class="hotInfoTag" style="color:#FF5C00;font-weight: 600;">
									1元={{(item.piecesNum / item.price).toFixed(4)}}{{item.commodityUnit}}</div>
								<div class="hotInfoTag" style="color:#777A8A;font-weight: 600;">
									{{( item.price / item.piecesNum).toFixed(4)}}元/{{item.commodityUnit}}
								</div>
							</div>
							<el-input-number v-else class="table4" v-model="item.buyNum" :min="1" :max="item.num"
								label="描述文字"></el-input-number>
							<!-- <span v-else class="table4">{{item.buyNum}}</span> -->
							<span v-if="info.type == 'hot'" class="table5">
								<span
									style="color:#FF5C00;font-weight: 600;">&emsp;{{(Number(info.numValue) *   item.price / item.piecesNum || 0).toFixed(2)}}元</span>
							</span>
							<span v-else class="table5">
								<!-- <span class="price">￥{{ Number(item.buyNum) * Number(item.price)}}</span> -->
								<span class="price">￥{{ (Number(item.price) *  Number(item.buyNum)).toFixed(2)}}</span>
							</span>
						</div>

						<div v-if="tradeRemark != '' && tradeRemark != null && tradeRemark != undefined " class="hint"
							v-html="tradeRemark"></div>
						<div>
							<!-- <div class="fl formItem">
								<div class="submitLable">
									<span style="color:#FF3C3C;">*</span>
									<span class="lableText">您的联系QQ号：</span>
								</div>
								<div class="submitValue">
									<el-input v-prevent-space v-model="submitQQ" placeholder="请输入您的联系QQ号"></el-input>
								</div>
							</div> -->
							<div class="fl formItem">
								<div class="submitLable">
									<span style="color:#FF3C3C;">*</span>
									<span class="lableText">您的联系电话：</span>
								</div>
								<div class="submitValue">
									<el-input v-prevent-space v-model="submitPhone" placeholder="请输入您的联系电话"></el-input>
								</div>
							</div>
							<el-form :model="rulesForm" ref="dynamicValidateForm">
								<div class="formItem fl" v-for="(item,index) in rulesForm.data">
									<div class="submitLable"><span v-if="item.isRequired == 1"
											style="color:#FF3C3C;">*</span>
										<span class="lableText">{{item.name}}：</span>
									</div>
									<el-form-item label="" :key="item.id" :prop="'data.'+index+'.value'"
										:rules="{ required: true, message: '请输入'+item.name, trigger: 'blur' }">


										<div class="submitValue">
											<el-input v-prevent-space v-if="item.form == 1" v-model="item.value"
												:placeholder="item.inputTip"></el-input>
											<el-select v-if="item.form == 3" style="width: 400px;"
												:popper-append-to-body="false" v-model="item.value"
												:placeholder="item.inputTip">
												<el-option v-for="(items,indexs) in item.options.split(' ')"
													:key="items" :label="items" :value="items">
												</el-option>
											</el-select>
											<div v-if="item.form == 2">
												<el-radio v-model="item.value" :label="items"
													v-for="(items,indexs) in item.options.split(' ')">{{items}}</el-radio>
											</div>
											<el-cascader ref="cascader" :append-to-body="false" v-if="item.form == 4"
												style="width: 400px;" v-model="item.value" :options="districtList"
												:props="districtProps"></el-cascader>
												
											<div class="role">
												<el-select no-data-text="暂无历史角色，请键入您的角色名" :filter-method="$event=>dataFilter($event,index)" filterable
													allow-create v-if="item.form == 5 " :reserve-keyword="true"
													ref="searchSelect" style="width: 400px;" :popper-append-to-body="false"
													v-model="item.value" :placeholder="item.inputTip">
													<el-option v-for="(items,indexs) in roleList" :key="items.receiveRole"
														:label="items.receiveRole" :value="items.receiveRole">
													</el-option>
												</el-select>
											</div>
											
										</div>


									</el-form-item>
									<div v-if="item.form == 5" class="submitRemark">
										<el-radio v-model="item.isSave" :label="items.value"
											v-for="(items,indexs) in isSave">{{items.text}}</el-radio>
									</div>
									<!-- <div v-if="item.remark != ''" class="submitRemark">{{item.remark}}</div> -->
								</div>
							</el-form>


						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="lableTop" v-if="showInsure">
			<el-row class="content">
				<el-col :span="24">
					<div class="screen" style="margin-top: 16px;">
						<div class="mainTop fl" style="width: 300px;">
							<span class="topItem itemAction">选择交易安全险</span>
						</div>

						<div class="formItem " style="margin-top: 50px;display: flex;">
							<div class="item1" style="color:#FF3C3C;"><span style="color:#FF3C3C;">*</span>购买交易安全险：</div>
							<div>
								<div class="secureList">
									<div v-for="(item,index) in insureList" @click="insureIndex = index"
										class="secureItem " :class="{'secureAction':insureIndex === index}">
										<div v-if="insureIndex === index" class="secureTopAction">保障升级，赠永久包赔，让交易无忧</div>
										<div>
											<span style="font-size: 14px;margin-right: 5px;"
												v-if="item.days == '180'">享普通包赔</span>
											<span style="font-size: 14px;margin-right: 5px;"
												v-else-if="item.days == '360'">享进阶包赔</span>
											<span style="font-size: 14px;margin-right: 5px;" v-else>享普通包赔</span>

										</div>
										<div style="display: flex;flex-direction: column;margin-left: 15px;">
											<span
												style="font-size: 14px;margin-bottom: 5px;">￥{{getInsurePrice(item.rate)}}</span>
											<span
												style="font-size: 14px;opacity: .7;margin-bottom: 5px;">赔付百分比:{{item.paidRate}}%</span>
											<span
												style="font-size: 14px;opacity: .7;margin-bottom: 5px;">保障额度:{{getInsurePrice(item.paidRate)}}</span>
										</div>

									</div>

									<div @click="insureIndex = ''" class="secureItem "
										:class="{'secureAction':insureIndex === ''}"
										style="padding: 15px;text-align: center;">
										<span
											style="text-align: center;margin-left: 60px;font-size: 14px;">不购买(无赔付保障)</span>

										<!-- <span>{{item.rate}}%</span> -->
									</div>

								</div>

								<div class="secureTip">未购买保险的商品，出现风险的概率更高。成功购买保险服务后，将降低您的风险及损失</div>
								<div class="secureTip1">
									<span>1、购买交易安全险的商品带有"永久包赔","卖家投保"标识，商品更受欢迎</span>
									<span>2、"账号交易"购买保险服务后，"永久包赔"生效，若出现被卖家恶意找回等情况，平台核实后先行赔付给买家</span>
									<span>3、"账号交易"订单，购买保险后，交易双方必须签署交易合同，发生找回情况，平台法务将第一时间联合警方进行线下追回</span>
									<span>4、"金币交易"订单，购买保险后，若发生被官方回收扣除情况，平台将核实后全额赔付</span>
									<span>5、"代练交易"订单，购买保险后，若发生被盗，封号等情况，平台核实后将全额赔付</span>
								</div>
								<div class="secureTip2">
									<span>本模块为保险投保页面，选择购买表示您已阅读，理解并同意</span>
									<span style="color: #0085FF;cursor:pointer;"
										@click="$router.push('/serviceCenter/3/1808436948773982209')">《中国平安购买须知》</span>
									<span style="color: #0085FF;cursor:pointer;"
										@click="$router.push('/serviceCenter/2/1808234874761199618')">《什么是交易安全险》</span>
									<span style="color: #0085FF;cursor:pointer;"
										@click="$router.push('/serviceCenter/3/0')">《隐私政策》</span>
									<span style="color: #0085FF;cursor:pointer;"
										@click="$router.push('/serviceCenter/3/1808435680399675394')">《保险经纪委托书》</span>
									<span style="color: #0085FF;cursor:pointer;"
										@click="$router.push('/serviceCenter/3/0')">《客户告知书》</span>
									<span>。为保障您的权益将会安全记录您的操作</span>
								</div>
							</div>

						</div>

					</div>
				</el-col>
			
			</el-row>
		</div>

		<div class="lableTop">
			<el-row class="content">
				
				<el-col :span="24">
					<div class="screen" style="margin-top: 16px;margin-bottom: 60px;">
						<div class="mainTop fl">
							<span class="topItem itemAction" v-if="shopList[0].gameCommodityCategory == 8">临时保证金</span>
							<span class="topItem itemAction"
								v-else-if="shopList[0].gameCommodityCategory == 2">佣金</span>
							<span class="topItem itemAction" v-else>确认结算订单</span>
						</div>
						<div class="payOrder" style="margin-top: 30px;">
							<span v-if="info.type == 'receiving'" class="payLable">预估收益：</span>
							<span v-else class="payLable">应付金额：</span>
							<span v-if="info.type == 'hot'" class="payPrice">
								<span>&emsp;{{(Number((Number(info.numValue) * (  shopList[0].price / shopList[0].piecesNum) || 0).toFixed(2)) + Number(totlePrice())).toFixed(2)}}元</span>
							</span>
							<span v-else class="payPrice">¥{{(Number(totlePrice1() )+ Number(totlePrice())).toFixed(2)}}
							</span>
						</div>
						<div class="payOrder" v-if="insureIndex !== '' && this.insureList.length != 0 ">
							<span class="payLable">包含保险费：</span>

							<span class="payPrice" style="font-size: 18px;">¥{{totlePrice()}}</span>
							<span class="payLable" style="margin-left: 8px;">(已投保{{shopList.length}}个商品)</span>
						</div>
						<div class="payOrder">
							<span class="agreement">提交订单即表示我已阅读并同意</span>
							<span v-if="shopList[0].gameCommodityCategory == 8"
								@click="$router.push('/serviceCenter/3/1804449768665804802')"
								class="agreementLink">《CC网络代练协议(工作室方)》</span>
							<span v-else-if="shopList[0].gameCommodityCategory == 2"
								@click="$router.push('/serviceCenter/3/1804450134736269313')"
								class="agreementLink">《CC网络代练交易协议(玩家)》</span>
							<span v-else class="agreementLink">《买家购买协议》</span>
						</div>

						<button v-if="shopList[0].gameCommodityCategory == 8" @click="toOrderPay"
							class="purchase">我要接单</button>
						<button v-else-if="shopList[0].gameCommodityCategory == 2" @click="toOrderPay"
							class="purchase">邀请代练</button>
						<button v-else-if="info.type == 'receiving'" @click="toOrderPay" class="purchase">立即出货</button>
						<button v-else @click="toOrderPay" class="purchase">立即购买</button>

					</div>
				</el-col>
			</el-row>
		</div>

		<shopping-card></shopping-card>
		<footer-bars></footer-bars>
	</div>
</template>

<script>
	import {
		singleReady,
		orderFieldList,
		insureList,
		districts,
		onePay,
		receiveRoleList,
		roleAdd,
		realInfo
	} from '@/api/index.js'
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import footerBars from '@/components/footerBars.vue';
	export default {
		components: {
			layoutTop,
			shoppingCard,
			footerBars
		},
		data() {
			return {
				formShow: false,
				// 区服id
				districtId: '',
				// 跨区id
				spannedId: '',
				isShow: false,
				step: 2,
				info: {},
				submitQQ: '',
				submitPhone: '',
				// 角色列表
				roleList: [],
				shopList: [],
				formList: [],
				rulesForm: {
					data: []
				},

				showInsure: false,
				// 保险列表
				insureList: [],
				insureIndex: '',
				districtList: [],
				isSave: [{
						text: '保存角色',
						value: 1
					},
					{
						text: '不保存角色',
						value: 0
					}
				],

				districtProps: {
					value: 'districtId',
					label: 'districtName',
				},
				tradeRemark: ''
			}
		},
		created() {
			// if (localStorage.getItem('confirmOrderQQ') != null) {
			// 	this.submitQQ = localStorage.getItem('confirmOrderQQ')
			// } else {
			// 	if (!this.isEmpty(this.$store.state.userInfo.qq)) {
			// 		this.submitQQ = this.$store.state.userInfo.qq
			// 	}
			// }
			if (localStorage.getItem('confirmOrderPhone') != null) {
				this.submitPhone = localStorage.getItem('confirmOrderPhone')
			} else {
				if (!this.isEmpty(this.$store.state.userInfo.account)) {
					this.submitPhone = this.$store.state.userInfo.account
				}
			}
			
			this.info = JSON.parse(this.$route.query.info)
			singleReady(this.info).then(res => {
				if (res.code == 200) {
					this.shopList = res.result.getShopCartList
					res.result.getShopCartList.forEach(item => {
						if (item.isSendInsure == 0) {
							this.showInsure = true
						}
					})
					this.tradeRemark = res.result.tradeRemark
					this.districtId = res.result.getShopCartList[0].districtId
					this.spannedId = res.result.getShopCartList[0].spannedId
					this.getDistrictList(res.result.getShopCartList[0].gameId, res.result.commodityTypeId)

					this.getInsureList(res.result.commodityTypeId)
					this.getReceiveRoleList()
					this.isShow = true
				} else {
					this.$router.go(-1);
				}

			})
			// }

		},
		methods: {
			setTitle(){
				this.info.numValue = parseInt(this.info.numValue)
				
			},
			// 获取角色列表
			getReceiveRoleList() {
				if (this.districtId == '' || this.districtId == null) {
					return
				}
				receiveRoleList({
					districtId: this.districtId,
					spannedId: this.spannedId,
					pageNo: '1',
					pageSize: '1000'
				}).then(res => {
					if (res.code == 200) {
						this.roleList = res.result.records
					}
				})
			},
			// 设置最小值或者最大值
			minValue(e, index) {
				console.log(e)

				if (this.shopList[index].lowerValue < this.shopList[index].minPrice) {
					this.message('不能小于最小出价范围', 'error')
					this.shopList[index].lowerValue = this.shopList[index].minPrice
					return
				}
				if (this.shopList[index].lowerValue > this.shopList[index].price) {
					this.message('不能大于最大出价范围', 'error')
					this.shopList[index].lowerValue = this.shopList[index].price
					return
				}
			},
			// 获取区服列表
			getDistrictList(id, commodityTypeId) {
				if (this.districtId == '' || this.districtId == null) {
					return
				}
				districts({
					id: id,
					districtId: this.districtId,
					spannedId: this.spannedId,
				}).then(res => {
					if (res.code == 200) {
						this.districtList = res.result
						this.getOrderFieldList(commodityTypeId)
					}

				})
			},
			// 去支付
			toOrderPay() {
				this.$refs.dynamicValidateForm.validate((valid) => {
					//提交表单前验证表单必填项是否都填写，如果都填写，valid返回true,可以提交表单
					console.log(valid)
					if (valid) {

						if (this.info.type == 'hot') {
							if (this.info.minOrderNum > this.info.numValue) {
								this.message(`最少购买${this.info.minOrderNum}件`, 'error')
								return
							}
						}
						let infos = {
							isSave: 1,
							addShopCartList: [],
							gameOrderInfos: [],
							insureId: '',
							priceReduction: 0,
							qq: this.submitQQ,
							phone: this.submitPhone
							// tradeType:this.shopList[0].tradeType
						}
						if (this.shopList[0].lowerValue != undefined && this.shopList[0].lowerValue != '' && this
							.shopList[0]
							.lowerValue != null) {
							if (this.shopList[0].lowerValue != this.shopList[0].price) {
								infos.priceReduction = 1
							}

						}
						if (this.insureList.length != 0 && this.insureIndex !== '') {
							infos.insureId = this.insureList[this.insureIndex].id
						}
						// localStorage.setItem('confirmOrderQQ', this.submitQQ);
						localStorage.setItem('confirmOrderPhone', this.submitPhone);

						this.rules = []
						this.rulesForm.data.forEach(res => {
							let info = {
								form: res.form,
								content: res.value,
								title: res.name,
								sort: res.sort,
								fieldId: res.id,
								// 默认1 卖家2
								orderId: 1 
							}
							if (res.form == 4) {
								info.content = this.$refs.cascader[0].presentText
								info.districtId = res.value[0]
							}
							if (res.form == 5) {
								info.isSave = res.isSave
							}
							infos.gameOrderInfos.push(info)
						})
						this.shopList.forEach(res => {
							let info = {
								id: res.id,
								num: res.buyNum,
								price: '',
								shopCartId: res.shopCartId,
							}
							if (this.info.type == 'hot') {
								info.num = this.info.numValue
							}
							if (res.lowerValue != undefined && res.lowerValue != '' && res.lowerValue !=
								null) {
								info.price = res.lowerValue
							} else {
								info.price = res.price
							}


							infos.addShopCartList.push(info)
						})
						
						realInfo().then(rale => {
								if (rale.code == 200) {
									if (rale.result == null || rale.result.idCardAudit != 1 || rale.result.faceAudit != 1) {
										this.message('请先进行实名认证','error')
										setTimeout(()=>{
											this.$router.push({
												path: '/mineIndex/28'
											})
										},1500)
										
									} else {
														
										if (this.info.type == 'hot') {
											onePay(infos).then(res => {
												if (res.code == 200) {
													this.payInfo = res.result
													this.payInfo.createTime = this.sjCurrent(6)
													// localStorage.setItem('orderPay', JSON.stringify(this.payInfo));
													this.$router.push({
														path: '/orderPay',
														query: {
															info: JSON.stringify(this.payInfo)
														}
													})
												}
										
											})
										} else if (this.info.type == 'receiving') {
											onePay(infos).then(res => {
												if (res.code == 200) {
													this.payInfo = res.result
													this.message('卖出成功，等待商家付款', 'success')
													setTimeout(() => {
														this.$router.push({
															path: '/chat/0',
										
														})
													}, 1000)
												}
										
											})
										} else {
											onePay(infos).then(res => {
												if (res.code == 200) {
													this.payInfo = res.result
													this.payInfo.createTime = this.sjCurrent(6)
													// localStorage.setItem('orderPay', JSON.stringify(this.payInfo));
													this.$router.push({
														path: '/orderPay',
														query: {
															info: JSON.stringify(this.payInfo)
														}
													})
												}
										
											})
										}
										
									}
								}
							})
						
						

						
					}
				});






			},
			getOrderFieldList(id) {
				if (this.shopList[0].districtId == '' || this.shopList[0].districtId == null || this.shopList[0]
					.districtId == undefined) {
					this.formShow = true
					return
				}
				let type = 1
				if (this.info.type == 'receiving') {
					type = 2
				}

				orderFieldList({
					gameTypeId: id,
					orderFieldType: type,
					districtId: this.shopList[0].districtId
				}).then(res => {
					this.formList = []
					this.rules = []
					this.rulesForm.data = []
					res.result.forEach((data, index) => {
						if (data.form == 4) {
							if (data.gameOrderInfo != null && data.gameOrderInfo != undefined) {
								if (data.gameOrderInfo.districtId == null) {
									data.value = ''
								} else {
									data.value = [data.gameOrderInfo.districtId]
								}

							} else {
								data.value = ''
							}
						} else {
							if (data.gameOrderInfo != null && data.gameOrderInfo != undefined) {
								data.value = data.gameOrderInfo.content
							} else {
								data.value = ''
							}
							if (data.form == 5) {
								data.isSave = 1
							}
						}
						this.formList.push(data)
						this.rulesForm.data.push(data)
					})

					// console.log(this.rulesForm)
					this.formShow = true
				})

			},




			totlePrice() {

				let totalPrice = 0
				if (this.shopList[0].lowerValue != undefined && this.shopList.lowerValue != '') {
					totalPrice = this.shopList[0].lowerValue
					
				} else {
					if (this.shopList.length == 1) {
						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList[0].price
						} else {
							totalPrice = this.shopList[0].buyNum * this.shopList[0].price
						}
						if (this.info.type == 'hot') {
							totalPrice = (Number(this.info.numValue) * (this.shopList[0].price / this.shopList[0]
								.piecesNum))
						}
					} else {
						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.price
								} else {
									return accumulator + 0
								}
							}, 0);
						} else {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.buyNum * currentValue.price
								} else {
									return accumulator + 0
								}
							}, 0);
						}

					}
				}
				if (this.insureIndex === '' || this.insureList.length == 0) {
					return 0
				} else {
					if (totalPrice * this.insureList[this.insureIndex].rate / 100 > this.shopList[0].minInsure) {
						return (totalPrice * this.insureList[this.insureIndex].rate / 100).toFixed(2)
					} else {
						return this.shopList[0].minInsure
					}
				}

			},
			totlePrice1() {
				let totalPrice = 0
				if (this.shopList[0].lowerValue != undefined && this.shopList.lowerValue != '') {
					totalPrice = this.shopList[0].lowerValue
				} else {
					if (this.shopList.length == 1) {
						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList[0].price
						} else {
							totalPrice = this.shopList[0].buyNum * this.shopList[0].price
						}

					} else {
						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.price
								} else {
									return accumulator + 0
								}

							}, 0);
						} else {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.buyNum * currentValue.price
								} else {
									return accumulator + 0
								}

							}, 0);
						}
					}
				}
				return totalPrice
			},
			getInsureList(id) {
				insureList({
					gameTypeId: id
				}).then(res => {
					this.insureList = res.result
				})
			},
			dataFilter(val, index) {
				this.$set(this.formList[index], 'value', val)
			},
			getInsurePrice(rate) {
				let totalPrice = 0
				if (this.shopList[0].lowerValue != undefined && this.shopList.lowerValue != '') {
					totalPrice = this.shopList[0].lowerValue
				} else {
					if (this.shopList.length == 1) {

						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList[0].price
						} else {
							totalPrice = this.shopList[0].buyNum * this.shopList[0].price
						}
						if (this.info.type == 'hot') {
							totalPrice = (Number(this.info.numValue) * (this.shopList[0].price / this.shopList[0]
								.piecesNum))
						}

					} else {
						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.price
								} else {
									return accumulator + 0
								}

							}, 0);
						} else {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								console.log(currentValue)
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.buyNum * currentValue.price
								} else {
									return accumulator + 0
								}

							}, 0);
						}

					}
				}


				if (totalPrice * rate / 100 > this.shopList[0].minInsure) {
					return (totalPrice * rate / 100).toFixed(2) + '元'
				} else {
					return (this.shopList[0].minInsure).toFixed(2) + '元'
				}

			},
			getInsurePrice1(rate) {
				let totalPrice = 0
				if (this.shopList[0].lowerValue != undefined && this.shopList.lowerValue != '') {
					totalPrice = this.shopList[0].lowerValue
				} else {
					if (this.shopList.length == 1) {

						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList[0].price
						} else {
							totalPrice = this.shopList[0].buyNum * this.shopList[0].price
						}
						if (this.info.type == 'hot') {
							totalPrice = (Number(this.info.numValue) * (this.shopList[0].price / this.shopList[0]
								.piecesNum))
						}

					} else {
						if (this.shopList[0].gameCommodityCategory == 8) {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.price
								} else {
									return accumulator + 0
								}

							}, 0);
						} else {
							totalPrice = this.shopList.reduce((accumulator, currentValue) => {
								console.log(currentValue)
								if (currentValue.isSendInsure != 1) {
									return accumulator + currentValue.buyNum * currentValue.price
								} else {
									return accumulator + 0
								}

							}, 0);
						}

					}
				}

				return totalPrice
				// if (totalPrice * rate / 100 > this.shopList[0].minInsure) {
				// 	return (totalPrice * rate / 100).toFixed(2) + '元'
				// } else {
				// 	return (this.shopList[0].minInsure).toFixed(2) + '元'
				// }

			}

		}

	}
</script>

<style scoped lang="scss">
	.lableTop {
		position: relative;
		// min-width: 100%;
		background-color: #F8F8F8;

		.lableImage {
			width: 100%;
			height: 150px;
			position: absolute;
		}
	}

	.lableBg {
		padding-top: 20px;
		height: 170px;
		display: flex;

		.lableTitle {
			font-weight: bold;
			font-size: 28px;
			color: #FFFFFF;
		}

		.lableDetaile {
			font-weight: 400;
			font-size: 18px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}

	.stepList {
		width: 600px;
		margin: 30px auto;
	}

	.step {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.step::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #DDDDDD;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepAction {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.stepAction::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #0085FF;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepSuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	::v-deep .el-step__title.is-process {
		color: #C0C4CC;
		font-weight: 500;

	}

	.screen {
		color: #777A8A;
		background: #FFFFFF;

		margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		padding: 0 0 24px;
	}

	.mainTop {
		border-bottom: 1px solid #ECECEC;

		.topItem {
			width: 140px;
			height: 66px;
			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			line-height: 66px;
			text-align: center;
			margin: 0 10px;
		}

		.itemAction {
			border-bottom: 3px solid #FF5C00;
		}
	}

	.thTable {
		font-weight: 600;
		font-size: 16px;
		color: #000000;
		margin: 20px;
		height: 64px;
		background: #F7F7F7;
		padding: 0 30px;
		justify-content: space-between;

		.must {
			position: relative;
		}

		.must::after {
			position: absolute;
			content: '*';
			color: red;
			top: 0;
			left: -20px;
		}

	}

	.tableInfo {
		font-weight: 600;
		font-size: 20px;
		color: #000000;
		margin: 20px;
		// height: 64px;

		padding: 0 30px 30px;
		justify-content: space-between;
		border-bottom: 1px solid #ECECEC;
	}

	.table1 {
		width: 35%;
		display: flex;

		.infoImage {
			width: 100px;
			height: 100px;
			border-radius: 6px 6px 6px 6px;
		}
	}

	.table2 {
		width: 15%;
		text-align: center;
	}

	.table3 {
		width: 20%;
		text-align: center;
	}

	.table4 {
		width: 15%;
		text-align: center;
	}

	.table5 {
		width: 15%;
		text-align: right;

		.price {
			font-weight: 600;
			font-size: 20px;
			color: #FF5C00;

			text-align: right;

		}
	}

	.mustInput {
		width: 154px;
		height: 36px;
		background: #FFFFFF;
		border-radius: 4px 0px 0px 4px;
		border: 1px solid #DEDEDE;
		justify-content: space-between;
		margin: 0 auto;

		input {
			border: none;
			padding-left: 20px;
			font-size: 16px;
			width: 90px;
		}

		.suffix {
			width: 46px;
			height: 36px;
			background: #F8F8F8;
			line-height: 36px;
			text-align: center;
			font-size: 14px;
		}


	}

	.scope {
		font-weight: 400;
		font-size: 12px;
		color: #A5A7B1;
		line-height: 21px;
		margin-top: 8px;
	}

	.infoTitle {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
		width: 412px;

		.infoAccount {
			font-size: 12px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}


	}

	.infoTitle:hover {
		color: #FF5C00;
	}

	.idItem {
		font-weight: 400;
		font-size: 12px;
		color: #A5A7B1;
		margin-top: 8px;
	}

	.hint {
		margin:  20px;
		padding: 10px;
		// height: 300px;
		background: #FFFCF0;
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #FFCC33;
		font-weight: 500;
		font-size: 14px;
		color: #777A8A;
		line-height: 20px;

	}

	.formItem {
		margin-bottom: 20px;

		.submitLable {
			width: 30%;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 14px;
				color: #1D223C;
				line-height: 30px;
				position: relative;
			}


		}

		.submitValue {
			width: 400px;
			margin: 0 20px;
			position: relative;

			::v-deep .el-cascader {
				width: 400px;
			}

			::v-deep .el-input__inner {
				width: 400px;
			}

			::v-deep .el-popper {
				position: absolute !important;
				top: auto !important;
				left: auto !important;
			}
		}

		.submitRemark {
			// width: 40%;
			margin-left: 50px;
			color: red;
		}
	}

	.payOrder {
		padding: 12px 20px;

		.payLable {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
		}

		.payPrice {
			font-weight: bold;
			font-size: 24px;
			color: #FF5C00;
		}

		.agreement {
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}

		.agreementLink {
			font-weight: 400;
			font-size: 14px;
			color: #0085FF;
			cursor:pointer;
		}

	}

	.purchase {
		width: 240px;
		height: 46px;
		background: #FF5C00;
		border-radius: 8px 8px 8px 8px;
		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		margin-left: 20px;
		cursor:pointer;
	}

	.secureList {
		display: flex;
		flex-wrap: wrap;
		width: 800px;
		align-items: flex-end;

		.secureItem {
			width: 280px;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #DEDEDE;
			// text-align: center;
			// line-height: 80px;
			font-weight: 400;
			font-size: 22px;
			color: #1D223C;
			margin-bottom: 40px;
			margin-right: 16px;
			position: relative;
			display: flex;
			padding-top: 20px;
			padding-left: 20px;
			cursor:pointer;
		}

		.secureAction {
			border-color: #0085FF;
			border-width: 3px;
			color:#FF3C3C;
		}
	}

	.item1 {
		width: 300px;
		text-align: right;
		font-size: 14px;
	}

	.secureTip {
		font-weight: 400;
		font-size: 14px;
		color: #FF3C3C;
		line-height: 20px;
	}

	.secureTip1 {
		width: 696px;
		// height: 100px;
		background: #FFFCF0;
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #FFCC33;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 20px;
		margin-top: 24px;
		padding: 10px 10px;
		font-size: 14px;
		color: #000;
		line-height: 35px;
	}

	.secureTip2 {
		width: 716px;
		font-weight: 400;
		font-size: 14px;
		color: #000000;
		margin-top: 16px;
	}

	.secureTopAction {
		width: 305px;
		position: absolute;
		top: -40px;
		height: 40px;
		left: -2px;
		background-color: #0085FF;
		text-align: center;
		color: #fff;
		border-radius: 6px 6px 0px 0px;
		line-height: 40px;
		font-size: 14px;
	}

	::v-deep .el-form-item {
		margin-bottom: 0px;
	}

	::v-deep .el-form-item__error {
		left: 22px
	}
	.role{
		::v-deep .el-input__suffix{
			display: none;
		}
		::v-deep .el-input__inner{
			cursor: text;
		}
	}
	
</style>