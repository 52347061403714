<template>
	<div style="background-color: #F8F8F8;">
		<layout-top></layout-top>
		<div class="lableTop " >
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row class="content">
				<el-col :span="24">
					<div class="lableBg">
						<img style="width: 100px;height: 100px;border-radius: 10px;margin-right: 15px;"
							:src="auth.logo ||require('@/static/image/index/profile.png')" />
						<div class=" gameClass">
							<div class="lableTitle">{{auth.storeName}}</div>
							<span class="lableDetaile" v-if="auth.isAuth ==0">普通商家</span>
							<span class="lableDetaile" v-if="auth.isAuth ==1">认证商家</span>
							
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="lableTop">
			<el-row style="width:1200px;margin: 0 auto;">
				
				<el-col :span="24">
					<div class="screen" style="margin-top: 0px;padding-bottom: 20px;padding-top: 40px;">
						<div class="screenItem fl" >
							<div class="fl" style="padding: 10px 20px;background-color: #F7F7F7;width: 100%;">
								<div class="fl" style="width: 50%;color: #000;font-size: 16px;font-weight: bold;text-align: center;">
									信息
								</div>
								<div
									style="color: #000;font-size: 16px;font-weight: bold;width: 15%;text-align: center;">
									价格</div>
								<div
									style="color: #000;font-size: 16px;font-weight: bold;width: 15%;text-align: center;">
									库存</div>
								<div
									style="color: #000;font-size: 16px;font-weight: bold;width: 20%;text-align: center;">
									服务保证</div>
								<div style="color: #000;font-size: 16px;font-weight: bold;width: 20%;text-align: center;">
									操作
								</div>
							</div>
						</div>
						<div class="screenItem fl" v-for="(item,index) in gameList">
							<div class="fl guarantee">
								<div style="width: 40%;display: flex;">
									<el-image v-if="item.gameCommodityCategory != 1 && item.gameCommodityCategory != 7"
										style="width: 148px; height: 148px;border-radius: 4px;flex-shrink: 0;"
										:src="item.icon" fit="cover"></el-image>
									<div>
										<div style="margin-left: 8px;position: relative;">
											<div class="infoTitle ">
												<div class="el2">
													<span
														v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6"
														class="infoAccount">账户</span>
													<span v-else class="infoImage">{{item.deliveryType_dictText}}</span>

													<span style="position: relative;"
														@click="toDetaile(item)">{{item.title}}
													</span>
												</div>

												<div class="showInfo"
													v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6">
													<div class="acc-triangle"></div>
													<div class="showTitle">{{item.title}}</div>
													<div class="showItem"
														v-for="items in item.gameCommodityFieldValueList">
														<div class="itemName">{{items.name}} : </div>
														<div class="itemValue">{{items.value}}</div>
													</div>

												</div>
											</div>

											<!-- <div class="infoSpan" v-if="item.gameCommodityFieldValueList.length != 0">
												<span class="spanItem"
													v-for="(items,indexs) in item.gameCommodityFieldValueList">{{items.value}}</span>
											</div> -->
											<div class="infoSpan" v-if="item.gameCommodityCategory == 8">
												<span class="spanItem">找代练</span>
											</div>
											<div class="infoSpan" v-if="item.gameCommodityCategory == 2">
												<span class="spanItem">代练接单</span>
											</div>
											<div class="area">
												<span style="color: #A5A7B1;">区服类型：</span>
												<span>{{item.districtName}}</span>
												<span v-if="item.spannedName != null"
													class="serverSide">{{item.spannedName}}</span>
											</div>
											<div class="area fl">
												<span style="color: #A5A7B1;">信用等级：</span>
												<div v-if="auth.shopGrade == 0">
													<img style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry3.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 1">
													<img v-for="items in 1"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry1.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 2">
													<img v-for="items in 2"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry1.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 3">
													<img v-for="items in 3"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry1.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 4">
													<img v-for="items in 4"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry1.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 6">
													<img v-for="items in 1"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 7">
													<img v-for="items in 2"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 8">
													<img v-for="items in 3"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 9">
													<img v-for="items in 4"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 11">
													<img v-for="items in 1"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry2.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 12">
													<img v-for="items in 2"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry2.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 13">
													<img v-for="items in 3"
														style="width: 14px;  height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry2.png')" alt="">
												</div>
												<div v-if="auth.shopGrade == 14">
													<img v-for="items in 4"
														style="width: 14px;height: 14px;margin-top: 3px;"
														:src="require('@/static/image/index/masonry2.png')" alt="">
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="accountPrice">￥{{item.price}}</div>
								<div class="accountNumber">{{item.num}}</div>
								<div class="accountService">
									<div class="fl serviceItem" v-if="item.isSendInsure == 1">
										<img style="width: 18px;height:184px;margin-right: 5px;"
											:src="require('@/static/image/index/bao.png')" />
										卖家投保
										<div class="servieHover">卖家已为商品投保，商品被找回、封号、回收等，可申请理赔</div>
									</div>
									<div class="fl serviceItem" v-else>
										<img style="width: 18px;height: 18px;margin-right: 5px;"
											:src="require('@/static/image/index/bao.png')" />
										商品可投保

										<div class="servieHover">此商品支持购买保险，商品被找回、封号、回收等，可申请理赔。</div>
									</div>
									<div v-if="item.deposit == 1" class="fl serviceItem" style="margin-top: 5px;">
										<img style="width: 18px;height: 18px;margin-right: 5px;"
											:src="require('@/static/image/index/pei.png')" />
										押金赔付
										<div class="servieHover">交易成功押金全额返还,商品被找回、封号、回收等，可获得赔付</div>
									</div>
								</div>
								<div class="accountPay">
									<div v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6"
										@click="toDetaile(item)" class="accountDetaile">查看详情</div>
									<div v-else-if="item.gameCommodityCategory ==2" @click="toDetaile(item)"
										class="accountDetaile">邀请代练</div>
									<div v-else-if="item.gameCommodityCategory ==8" @click="toDetaile(item)"
										class="accountDetaile">我要接单</div>
									<div v-else @click="toOrderPay(item)" class="accountDetaile">立即购买</div>
									<div v-if="item.gameCommodityCategory != 5 && item.gameCommodityCategory != 6"
										class="addCard" @click="addCard(item)">
										<img style="width: 24px;height: 24px;"
											:src="require('@/static/image/index/addCard.png')" />
									</div>
								</div>
							</div>
						</div>

						<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;">
							<div>为您找到
								<span style="color:#FF5C00;">{{totalNum}}</span>
								条记录
							</div>
							<el-pagination @size-change="handleSizeChange" @current-change="handlePageChange"
								:current-page="search.pageNo" :page-sizes="[10, 20, 30, 40]"
								:page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper"
								:total="totalNum">
							</el-pagination>
						</div>
					</div>

				</el-col>
				
			</el-row>
		</div>




		<shopping-card></shopping-card>
		<footer-bars></footer-bars>
	</div>
</template>

<script>
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import {
		gameCommodityMyList,
		authShop,
		shopCartAdd
	} from '@/api/index.js'
	import footerBars from '@/components/footerBars.vue';
	export default {
		components: {
			layoutTop,
			shoppingCard,
			footerBars
		},
		data() {
			return {
				screenShow: true,
				shopType: ['全部', '游戏账号', '游戏币', '矛盾的结晶体', '2023金秋礼包', '2023夏日套', '无瑕的协调结晶体'],
				shopTypeIndex: 1,
				acrossType: ['跨一', '跨二', '跨三', '跨四', '跨五', '跨六'],
				acrossTypeIndex: 1,
				otherList: [{
						title: '其他选项1',
						child: ['菜单1', '菜单2', '菜单3', '菜单4', '菜单5']
					},
					{
						title: '其他选项2',
						child: ['菜单1', '菜单2', '菜单3', '菜单4', '菜单5']
					}
				],
				search: {
					userId: '',
					
					pageNo: 1,
					pageSize: 10,
					orderType: '1',
					// isTrain: 0,
					flag: '',
					title: '',
					// type: 1,
					createTimeBegin: '',
					createTimeEnd: '',
					// // 交易类型
					deliveryType: null,
					// // 区服id
					districtId: null,
					// // 商品分类id
					commodityTypeId: null,
					
				},
				gameList: [],
				auth:{},
				totalNum:0
			}
		},
		created() {
			this.search.userId = this.$route.params.userId
			this.getList()
			this.getAuthShop(this.$route.params.id)
		},
		methods: {
			getList(){
				gameCommodityMyList(this.search).then(res => {
					this.gameList = res.result.records
					this.totalNum = res.result.total
					console.log(this.gameList)
				})
			},
			// 获取商家信息
			getAuthShop(id) {
				authShop({
					gameCommodityId: this.$route.params.id
				}).then(res => {
					this.auth = res.result
				})
			},
			handleSizeChange(e){
				this.search.pageSize = e
				this.getList()
			},
			handlePageChange(e){
				this.search.pageNo = e
				this.getList()
			},
			toDetaile(item) {
				this.$router.push('/gameDetaile/' + item.id)
			},
			// 添加购物车
			addCard(item) {
				shopCartAdd({
					id: item.id,
					num: 1,
					price: item.price,
				}).then(res => {
					if (res.code == 200) {
						this.message('添加成功！', 'success')
					}
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.lableTop {
		position: relative;
	
		.lableImage {
			width: 100%;
			height: 150px;
			position: absolute;
		}
	}
	
	.lableBg {
		margin-top: 30px;
		margin-bottom: 20px;
		// height: 150px;
		display: flex;
		cursor: pointer;
	
		.lableTitle {
			font-weight: bold;
			font-size: 24px;
			color: #FFFFFF;
		}
	
		.lableDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}



	.screen {
		color: #777A8A;
		background: #FFFFFF;

		margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		padding: 0 0 24px;
		margin-bottom: 30px;
	}

	.screenTop {
		justify-content: space-between;
		border-bottom: 1px solid #ECECEC;
		padding: 20px;
	}

	.screenDown {
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #ECECEC;
		padding: 4px 8px;
		margin-left: 10px;
	}

	::v-deep .el-tag.el-tag--info .el-tag__close {
		color: #FF5C00;
	}

	::v-deep .el-tag.el-tag--info .el-tag__close:hover {
		background-color: rgba(255, 92, 0, .3);
	}

	.screenItem {
		color: #A5A7B1;
		align-items: center;
		min-height: 64px;

		.screenLable {
			width: 140px;

			background: #F7F7F7;
			text-align: center;
			line-height: 64px;
			font-size: 18px;
		}

		.screenOptions {
			margin-left: 20px;
		}
	}

	.el-tag {
		margin-right: 20px;
		font-size: 18px;
	}

	.optionsItem {
		margin-right: 25px;
		font-size: 18px;
		color: #1D223C;
		padding: 5px 8px;
	}

	.optionsAction {
		background: #0085FF;
		border-radius: 24px 24px 24px 24px;
		color: #fff;
	}

	.el-dropdown-link {
		font-size: 18px;
		color: #000;
	}

	.otherItem {
		margin-right: 25px;
	}

	.sortList {
		height: 33px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #ECECEC;
		overflow: hidden;

		.sortItem {
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
			height: 33px;
			line-height: 33px;
			padding: 0 8px;
			border-right: 1px solid #ECECEC;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.sortItem:nth-child(3) {
			border: none;
		}

		.sortActin {
			background: #0085FF;
			color: #fff;
		}
	}

	.screenPrice {
		display: flex;
		align-items: center;
		margin-left: 24px;

		.price1 {
			width: 128px;
			background-color: #fff;
			border: 1px solid #ECECEC;
		}

		.price {
			width: 76px;
			border-right: 1px solid #ECECEC;
			background-color: #fff;
			height: 30px;
			line-height: 30px;
			border-radius: 4px 4px 4px 4px;

			input {
				width: 50px;
			}
		}

		.price2 {
			width: 76px;
			border: 1px solid #ECECEC;
			height: 30px;
			line-height: 30px;
			border-radius: 4px 4px 4px 4px;
			background-color: #fff;

			input {
				width: 50px;
			}
		}
	}

	.publish {
		display: flex;
		flex-direction: column;
		width: 164px;
		height: 48px;
		background: #FF5C00;
		border-radius: 4px 4px 4px 4px;
		align-items: center;
		justify-content: center;
		color: #fff;
	}

	.guarantee {
		padding: 20px;
		width: 100%;
		font-size: 18px;
		background: #FFFFFF;
		border-bottom: 1px solid #ececec;
		align-items: center;
	}

	.infoTitle {
		font-weight: 600;
		font-size: 16px;
		line-height: 30px;
		color: #000000;
		// width: 100%;
		position: relative;
		cursor: pointer;
		display: inline-block;
		// min-width: 50px;
	
		.infoAccount {
			font-size: 16px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}
	
		.infoImage {
			font-size: 14px;
			background: #0085FF;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
			margin-left: 5px;
		}
	
		.hoverDiv {
			// display: none;
			// position: absolute;
			// right: -10px;
		}
	
		.showInfo {
			display: none;
		}
	}
	
	.infoTitle:hover {
		color: #FF5C00;
		position: relative;
		display: inline-block;
	
		.showInfo {
	
			display: block;
			position: absolute;
			right: -480px;
	
			top: 50%;
			// left: 50%;
			transform: translate(0, -50%);
	
	
			padding: 16px 16px 15px 16px;
			background: #fff;
			border: 2px solid #0085FF;
			width: 436px;
			// height:400px;
			z-index: 300000;
			// top: 50%;
			box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
	
			.acc-triangle {
				width: 12px;
				height: 12px;
				border: 2px solid #0085FF;
				border-right: 0;
				border-bottom: 0;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				position: absolute;
				left: -7px;
				background: #fff;
				z-index: 3000;
				top: 48%;
			}
	
			.showTitle {
				line-height: 21px;
				font-size: 20px;
				padding-bottom: 16px;
				border-bottom: 1px dashed #EDEDED;
				font-weight: 600;
				margin-bottom: 15px;
			}
	
			.showItem {
				display: flex;
	
				.itemName {
					min-width: 120px;
					font-size: 14px;
					color: #999;
					margin-right: 10px;
				}
	
				.itemValue {
					font-size: 14px;
				}
			}
	
		}
	}

	.infoSpan {
		line-height: 34px;

		.spanItem {
			height: 32px;
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			background: #F5F5F5;
			border-radius: 2px 2px 2px 2px;
			padding: 5px 4px;
			margin-right: 10px;
		}
	}

	.serverSide {
		font-size: 16px;
		color: #FFE350;
		line-height: 19px;
		background-color: #1B2948;
		padding: 2px 4px;
		border-radius: 2px;
		position: relative;
		margin-left: 10px;

	}

	.serverSide::before {
		content: "";
		position: absolute;
		top: 8px;
		left: -4px;
		transform: rotate(-45deg);
		border-top: 10px solid #1B2948;
		/* 三角形的高度和颜色 */
		border-right: 10px solid transparent;
		/* 三角形的宽度 */
	}


	.area {
		font-weight: 400;
		font-size: 14px;
		color: #777A8A;
		margin-top: 4px;
	}

	.accountPrice {
		color: #FF5C00;
		font-weight: bold;
		font-size: 16px;
		width: 15%;
		text-align: center;
	}

	.accountNumber {
		color: #000;
		font-weight: bold;
		font-size: 16px;
		text-align: center;
		width: 15%;
	}

	.accountService {
		width: 15%;
		// margin: 0 auto;
		font-size: 18px;
		color: #1D223C;
	}

	.accountPay {
		display: flex;

		.accountDetaile {
			width: 128px;
			height: 44px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FF5C00;
			font-size: 16px;
			color: #FF5C00;
			text-align: center;
			line-height: 44px;
		}

		.accountDetaile:hover {
			background-color: #FF5C00;
			color: #fff;
		}

		.addCard {
			width: 24px;
			height: 24px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FF5C00;
			padding: 10px;
			margin-left: 10px;
		}
	}

	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 18px;
	}

	::v-deep .el-pager li {
		font-size: 18px;
	}

	::v-deep .el-dropdown-menu {
		min-width: 120px;
		position: absolute !important;
		top: 35px !important;
		// left: -45px !important;
	}

	.authText {

		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
		margin-left: 5px;
	}

	.serviceItem {
		position: relative;
		font-size: 16px;
		.servieHover {
			position: absolute;
			top: 35px;
			left: 35px;
			font-size: 14px;
			border: 1px solid #000;
			color: #000;
			padding: 3px 10px;
			display: none;
			white-space: nowrap;
		}

		.servieHover:hover {
			color: #000;
		}

	}

	.serviceItem:hover {
		color: #0085FF;

		.servieHover {
			position: absolute;
			top: 35px;
			left: 35px;
			font-size: 14px;
			border: 1px solid #000;
			color: #000;
			padding: 3px 10px;
			display: block;
			white-space: nowrap;
		}

		.servieHover:hover {
			color: #000;
		}

	}
</style>